import React, {useState, useContext} from 'react';
import {ReactComponent as CloseIcon} from "./assets/cerrar.svg";
import {ApiContext} from "../../utils/api/api-config";

const Comment = ({theComment, createdDate, deleteComment, reload}) => {

    const api = useContext(ApiContext);
    const [openEdition, setOpenEdition] = useState(false);
    const [editComment, setEditComment] = useState({
        text: theComment.text
    });

    const editCommentHandler = (e) => {
        setEditComment({
            ...editComment,
            text: e.target.value
        });
    };

    const sendEditedCommentHandler = (e) => {
        api.comment.putComments(e.target.parentNode.id, {...editComment}).then(() => {
            reload();
            setOpenEdition(!openEdition);
        });
    };

    const enableEditionComment = () => {
        setEditComment({
            ...editComment,
            text: theComment.text
        });
        setOpenEdition(!openEdition);
    }

    return (
        <>
            <div id={theComment.id} key={theComment.id} className='comment'>
                <div onClick={deleteComment} className="comment-delete">
                    <CloseIcon/>
                </div>
                <p className='comment-show-text'>{editComment.text}</p>
                <button className='comment-edit' onClick={enableEditionComment}>Editar Comentario</button>
                <div className='comment-extra-info'>
                    <ul>
                        <li>{theComment.createdBy.username}</li>
                        <li>{createdDate}</li>
                    </ul>
                </div>
            </div>
            {openEdition ? <div className='comment-edition-bg'>
                <div className='comment-edition'>
                    <h3>Comentario</h3>
                    <textarea className='comment-show-edition'
                              onChange={editCommentHandler}
                              value={editComment.text}>{editComment.text}</textarea>
                    <div id={theComment.id}>
                    <button className='comment-send'
                            onClick={sendEditedCommentHandler}>Enviar
                    </button>
                    <button className='comment-cancel'
                            onClick={enableEditionComment}>Cancelar
                    </button>
                    </div>
                </div>
            </div> : null}
        </>
    )
};

export default Comment;