import Login from "./views/pages/Login";
import Dashboard from "./views/Dashboard";
import DeviceInfo from "./views/Devices/DeviceInfo";
import DeviceInfoPublic from "./views/Devices/DeviceInfoPublic";
import CompanyList from "./views/Company/CompanyList";
import Applications from "./views/Apps/Applications";
import CompanyDevices from "./views/Company/CompanyDevices";
import DeviceList from "./views/Devices/DeviceList";
import UserList from "./views/User/UserList";
import LocalClient from "./views/LocalClient/LocalClient";

const getRoutes = ({iotClientApi})=> {

    const routes = [

        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
            icon: "tim-icons icon-compass-05",
            layout: "/app"
        },
        {
            path: "/deviceInfo/:serial",
            name: "DispositivoPublico",
            component: DeviceInfoPublic,
            hidden: true,
            layout: "/auth"
        },
        {
            path: "/device/:serial",
            name: "Dispositivo",
            component: DeviceInfo,
            hidden: true,
            layout: "/app"
        },
        {
            path: "/companies",
            name: "Compañías",
            component: CompanyList,
            icon: "tim-icons icon-molecule-40",
            layout: "/app"
        },
        {
            path: "/company/:id",
            name: "Compañía",
            component: CompanyDevices,
            layout: "/app",
            hidden: true
        },
        {
            path: "/applications",
            name: "Aplicaciones",
            component: Applications,
            icon: "tim-icons icon-puzzle-10",
            layout: "/app"
        },
        {
            path: "/devices",
            name: "Dispositivos",
            component: DeviceList,
            icon: "tim-icons icon-tap-02",
            layout: "/app"
        },
        {
            path: "/login",
            name: "Inicio",
            component: Login,
            layout: "/auth",
            hidden: true
        },
        {
            path: "/users",
            name: "Usuarios",
            component: UserList,
            icon: "tim-icons icon-single-02",
            layout: "/app"
        }
    ];

    if(iotClientApi && iotClientApi.ready())
        routes.push({
            path: "/iot-client",
            name: "Cliente iot local",
            component: LocalClient,
            icon: "tim-icons icon-laptop",
            layout: "/app"
        });

    return routes;
};

export default getRoutes;
