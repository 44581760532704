import React, {useCallback, useContext} from 'react';
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import useInput from "../utils/hooks/useInput";
import {ApiContext} from "../utils/api/api-config";
import {notify} from "../utils/Notifier";
import {useSelector} from "react-redux";

const loadingId='@NameFormModal';
const NameFormModal = ({edit, entity, onHide, onSave, additionalProps={}, title})=>{

    const nameIn=useInput(edit? edit.name:'');

    const api = useContext(ApiContext);
    const save= useCallback( ()=>{

        const params = {name:nameIn.value.trim(), ...additionalProps};
        if(!params.name) return notify.error('Debes agregar un nombre');

        if(edit)
            api[entity].update({id: edit.id, params, loadingId}).then(onSave? onSave:onHide);
        else
            api[entity].create({params, loadingId}).then(onSave? onSave:onHide);
    },[api, edit, entity, onHide, nameIn, additionalProps, onSave]);

    const loading=!!useSelector(({loadingIds})=>loadingIds[loadingId]);

    const titleText=title? title: (edit?'Editar':'Crear');

    return (
        <Modal isOpen>
            <ModalHeader>{titleText}</ModalHeader>
            <ModalBody>
                <Label>Nombre</Label>
                <input {...nameIn.bind} className='form-control'/>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' disabled={loading} onClick={onHide}>Cancelar</Button>
                <Button color='success' disabled={loading} onClick={save}>Guardar</Button>
            </ModalFooter>
        </Modal>
    );
};

export default NameFormModal;
