import React, {useEffect,useContext,useState, useCallback} from 'react';
import {Col, Row, Button,} from "reactstrap";
import './assets/DeviceInfoPublic.scss';
import aMomentAgoFormatter from "../../utils/aMomentAgo";
import {colorForDate} from "../../utils/modelUtils/deviceUtils";
import useBoolean from "../../utils/hooks/useBoolean";
import {notify} from "../../utils/Notifier";
import TemperatureCard from "./components/TemperatureCard";
import StorageCard from "./components/StorageCard";
import AppsTableCard from "./components/AppsTableCard";
import DeviceInfoCard from "./components/DeviceInfoCard";
import usePageTitle from "../../utils/hooks/usePageTitle";
import {ApiContext} from "../../utils/api/api-config";

const customProp = 'DeviceInfo.device';

const DeviceInfoPublic = ({match}) => {
  const api = useContext(ApiContext);
  const serial = match.params.serial;
        
  const [autoRefreshing, , , toggleAutoRefreshing] = useBoolean();

  usePageTitle(match.params.serial);

  const [device, setDevice] = useState({});
  const [notFound, setNotFound] = useState();
    
  const reload=useCallback(() => {
    api.devices.getPublic(serial)
      .then(device=>setDevice(device))
      .catch(error=>setNotFound(true))
  },[api,serial])

  useEffect(() => {
    reload();  
  }, [api,serial,reload]);

  useEffect(() => {
    if (autoRefreshing) {
      const intervalId = setInterval(reload, 5000);
      notify.info('Refrescando información cada 5 segundos.');
      return () => clearInterval(intervalId);
    }
  }, [autoRefreshing, reload]);

  const color = colorForDate(device && device.connectionDate);

  return (
    <div className='content DeviceInfoPublic overflow-hidden pt-0'>
      <Row xs={12} className='p-2 mt-3'>
        <Col xs={12} md={6}>
          <h2 className='device-title'>
            {
              device && 
              <>
                <span>{device.serial}</span> <span className='text-muted'>{device.serial}</span>
              </>
            }
            {
              !device && "Cargando..."
            }
            {
              notFound && "Dispositivo no encontrado"
            }
          </h2>
          {
            device && 
            device.connectionDate
              ? <h5 style={{color}}>
                  <span className='connection-dot' style={{backgroundColor: color}}/>
                  <span className='connection-text-size'>
                    Última conexión {aMomentAgoFormatter(device.connectionDate)}
                  </span>
                </h5>
              : 'Sin conexión registrada'
          }
        </Col>
        <Col>
          <Button
            className='pull-right'
            color={autoRefreshing ? 'success' : 'default'}
            onClick={toggleAutoRefreshing}
          >
            Auto-refresh
          </Button>
        </Col>
      </Row>
      {
        device &&
          <>
            <Row>
              <Col>
                <Row xs={12} md={6} className='p-2'>
                  <Col xs={12} md={6}>
                    <TemperatureCard device={device}/>
                  </Col>
                  <Col xs={12} md={6}>
                    <StorageCard device={device}/>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row xs={12} md={6} className='p-2'>
                  <Col>
                    <DeviceInfoCard device={device} publicInfo={true}/>
                  </Col>
                  <Col>
                    <AppsTableCard device={device} customProp={customProp} publicInfo={true}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
      }
    </div>
  );
};

export default DeviceInfoPublic;
