import React, {useContext, useEffect, useState, useCallback } from 'react';
import {ApiContext} from '../../utils/api/api-config';
import {useSelector} from 'react-redux';
import {Button, Col,Table, Row} from 'reactstrap';
import AddUserModal from "./AddUserModal"
import useBoolean from "../../utils/hooks/useBoolean";
import SwitchBtn from "./SwitchBtn";
import useCallbackCreator from "../../utils/hooks/useCallbackCreator";
import ReactBSAlert from "react-bootstrap-sweetalert";

const UserList = () => {
    const api = useContext(ApiContext);

    useEffect(() => {
        api.users.get({params: {pagination: false}});
    }, [api]);

    const users = useSelector(({api}) => api.users || []);

    const [addUser, startAddUser, stopAddUser] = useBoolean();
    const [editingUser, setEditingUser]=useState(null);
    //const [active, setActive] = useState(false);

    const stopEditingUser=useCallback(()=>setEditingUser(null),[]);
    const stopRemovingUser=useCallback(()=>setRemovingUser(null),[]);

    const [removingUser, setRemovingUser]=useState(null);

    /*
    const handleChange = ( isActive) => {
        setActive(!isActive);
   };
   */

    const userCrudCreator=useCallbackCreator((action)=>{
        const [type, index]=action.split('-');
        if(type==='edit') setEditingUser(users[index]);
        else if(type==='remove') setRemovingUser(users[index]);
    });

    const deleteUser=useCallback(()=>{
        api.users.delete({id:removingUser.id});
        stopRemovingUser();
    },[removingUser, stopRemovingUser, api]);

    const handleSwitchStatus = useCallback((e, id) => {
        api.users.update(
            {
                id: id,
                params: {
                    isActive: e
                }
            }
        );
    }, [api]);

    return <div className="content UserList">
        <Row>
            <Col xs={12}>
                <Button className='pull-right' onClick={startAddUser}>Agregar Usuario </Button>
            </Col>
        </Row>
        <Table striped>
            <thead>
                <tr>
                    <td>Usuario</td>
                    <td>Email</td>
                    <td>Rol</td>
                    <td>Compañía</td>
                    <td>Activo</td>
                    <td>Acciones</td>
                </tr>
            </thead>
                <tbody>
                    {users.map((user,i)=>
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            {user.role ? <td>{user.role.title}</td> : <td>Usuario Normal</td>}
                            {
                                user.company ? <td>{user.company.name}</td> : <td></td>
                            }
                            <td>
                                <SwitchBtn
                                    value={user.isActive}
                                    onChange={(e) => handleSwitchStatus(e, user.id)}
                            /></td>
                            <td>
                                <Button color='info' onClick={userCrudCreator('edit-'+i)}><i className='tim-icons icon-pencil'/></Button>
                                <Button color='danger' onClick={userCrudCreator('remove-'+i)}><i className='tim-icons icon-trash-simple'/></Button>
                            </td>
                        </tr>
                    )}
                </tbody>
        </Table>

        {addUser ?
            <AddUserModal
                user={addUser}
                onHide={stopAddUser}
                title={'Agregar usuario'}
                entity={'users'}
            />
            : null}
        {editingUser ?
            <AddUserModal
                edit={editingUser}
                onHide={stopEditingUser}
                title={'Editar usuario'}
                entity={'users'}
            />
            : null
        }
        {removingUser ?
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Estás seguro?"
                onConfirm={deleteUser}
                onCancel={stopRemovingUser}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, borrar!"
                cancelBtnText="Cancelar"
                showCancel
                btnSize=""
            >
               Eliminar usuario
            </ReactBSAlert>
            :null}
    </div>
};

export default UserList;
