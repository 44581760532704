import {useRef, useMemo} from 'react';

/**
 * This is intended to add callbacks inside loops.
 * The returned function creates a new callback each time is called with a different parameter.
 * If the parameter had been already used, the same function is returned.
 * When the inputs change, al functions are renewed to use the new callback.
 *
 * The returned function is a wrapper of the callback, which when called, calls the callback with the id with which it was
 * created as first parameter, and the rest of the received arguments as the following parameters.
 *
 * Copyright by Mark Ehrlich
 *
 * @param callback
 * @param inputs
 * @returns {function(*=): *}
 */
const useCallbackCreator = ( callback, inputs )=>{

    const callbacks = useRef({});

    useMemo(()=>{
        for( const id in callbacks.current )
            callbacks.current[id] = (...args)=>callback(id, ...args);
    // eslint-disable-next-line
    }, inputs);


    return (id)=>{
        if( !callbacks.current[id] )
            callbacks.current[id] = (...args)=>callback(id, ...args);
        return callbacks.current[id];
    }
};

export default useCallbackCreator;
