import React, {useContext} from "react";
import {Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Form, Input,
  InputGroupAddon, InputGroupText, InputGroup, Container, Col } from "reactstrap";
import { connect } from 'react-redux';
import { LOGIN_STATE } from "tide-api";
import useInput from '../../utils/hooks/useInput';
import { Redirect } from "react-router-dom";
import getRoutes from '../../routes';
import Alert from "reactstrap/es/Alert";
import dangerSmoke from "assets/img/card-danger.png";
import {ApiContext} from "../../utils/api/api-config";
import {IotClientContext} from "../../utils/api/IotClientApi";

const Login = ( props )=>{

  const api = useContext(ApiContext);
  const iotClientApi = useContext(IotClientContext);

  const usernameInput = useInput('');
  const passwordInput = useInput('');

  const login = ( e )=>{
    e.preventDefault();
    if( !(usernameInput.value && passwordInput.value) )
      return;
    api.login( usernameInput.value, passwordInput.value )
        .catch(e=>console.log(e));
  };

  let message;

  const routes = getRoutes(iotClientApi);
  if( props.loggedIn === LOGIN_STATE.LOGGED_IN )
    return <Redirect to={ routes[0].layout + routes[0].path }/>;

  else if( props.loggedIn === LOGIN_STATE.BAD_CREDENTIALS  )
    message = 'Lo sentimos, usuario y contraseña erróneos.';

  else if( props.loggedIn === LOGIN_STATE.LOGIN_ERROR  )
    message = 'Hubo un error al iniciar sesión.';

  //else if( props.loggedIn === LOGIN_STATE.TOO_MANY_ATTEMPTS  )
  //  message = <span>Demasiados intentos fallidos, la cuenta ha sido bloqueada.<br/> Inténtalo de nuevo en 5 minutos.</span>;

  return (
      <div className="content login-page">

        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={login} >
              <Card className="card-login card-white">
                <CardHeader>
                  <img
                      alt="..."
                      src={dangerSmoke}
                  />
                  <CardTitle tag="h1">iot</CardTitle>
                </CardHeader>
                <CardBody>
                  { message?
                  <Alert color='danger'>
                    {message}
                  </Alert>
                      :null}
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Usuario" type="text" autoFocus {...usernameInput.bind} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Contraseña" type="password" {...passwordInput.bind}/>
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                      block
                      className="mb-3"
                      color="danger"
                      type='submit'
                      size="lg"
                      disabled={!usernameInput.value || !passwordInput.value}
                  >
                    Entrar
                  </Button>

                  <div className="pull-right">
                    <h6>
                      <a
                          className="link footer-link"
                          onClick={e => e.preventDefault()}
                          href='/'
                      >
                        ¡Viva el iot!
                      </a>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
  );
};

const mapStateToProps = ( {api:{ loggedIn }} )=>({ loggedIn });

export default connect(mapStateToProps)(Login);
