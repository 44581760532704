import React, {useContext, useCallback, useEffect, useState} from 'react';
import {Col, Row, Button,} from "reactstrap";
import './assets/DeviceInfo.scss';
import useTideEntity from "../../utils/hooks/useTideEntity";
import {ApiContext} from "../../utils/api/api-config";
import aMomentAgoFormatter from "../../utils/aMomentAgo";
import {colorForDate, deviceFullSGroups} from "../../utils/modelUtils/deviceUtils";
import useBoolean from "../../utils/hooks/useBoolean";
import ReactBSAlert from "react-bootstrap-sweetalert";
import DeviceStats from "../../components/Devices/DeviceStats";
import {notify} from "../../utils/Notifier";
import CommentsSection from "../../components/CommentsSection/CommentsSection";
import TunnelToggleCard from "./components/TunnelToggleCard";
import TemperatureCard from "./components/TemperatureCard";
import StorageCard from "./components/StorageCard";
import CommandsRow from "./components/CommandsRow";
import AppsTableCard from "./components/AppsTableCard";
import DeviceInfoCard from "./components/DeviceInfoCard";
import IpAddressesCard from "./components/IpAddressesCard";
import SSHTunnelsRow from "./components/SSHTunnelsRow";
import usePageTitle from "../../utils/hooks/usePageTitle";
import ConsoleAsync from './components/ConsoleAsync/ConsoleAsync';
import WifiListCard from './components/WifiListCard/WifiListCard';

const customProp = 'DeviceInfo.device';
const tideEntityConfig = {filterBy: 'serial', customProp, requestFilters: {sGroups: deviceFullSGroups}};

const DeviceInfo = ({match, history}) => {

    const serial = match.params.serial;
    const api = useContext(ApiContext);
    const [device, loading, notFound, {reload}] = useTideEntity('devices', serial, tideEntityConfig);

    const [deleting, startDeleting, stopDeleting] = useBoolean();
    const [editingName, startEditingName, stopEditingName] = useBoolean();
    const [autoRefreshing, , , toggleAutoRefreshing] = useBoolean();
    const [fullStatsVisible, , , toggleFullStatsVisible] = useBoolean();
    const [me, setMe] = useState();

    usePageTitle(match.params.serial);

    useEffect(()=>{
        api.me.get().then(data=>setMe(data))
    },[api])

    useEffect(() => {
        if (autoRefreshing) {
            const intervalId = setInterval(reload, 5000);
            notify.info('Refrescando información cada 5 segundos.');
            return () => clearInterval(intervalId);
        }
    }, [autoRefreshing, reload]);

    const deleteDevice = useCallback(() => {
        api.devices.delete({id: device.id}).then(()=>history.push('/app/company/devices')).catch((er)=>console.log(er));
        //history.push('/app/company/devices');// + device.project.company.id);
       // api.devices.delete({id: device.id});

    }, [api, device, history]);

    const color = colorForDate(device && device.connectionDate);

    const editName = useCallback((alias) => {
        stopEditingName();
        api.devices.update({id: device.id, params: {alias, sGroups: deviceFullSGroups}, customProp});
    }, [api, device, stopEditingName]);

    return (
        <div className='content DeviceInfo'>
            {/*    -----  Header  -------   */}
            <Row>
                <Col xs="12">
                    <h2 className='device-title'>
                        {device && <><span className='link' onClick={startEditingName}>{device.alias}</span> <span
                            className='text-muted'>{device.serial}</span></>}
                        {!device && loading && "Cargando..."}
                        {notFound && "Dispositivo no encontrado"}
                    </h2>
                    {device && device.connectionDate ?
                        <h5 style={{color}}><span className='connection-dot' style={{backgroundColor: color}}/>Última
                            conexión {aMomentAgoFormatter(device.connectionDate)}</h5>
                        : 'Sin conexión registrada'
                    }
                </Col>
            </Row>
            <Row className='mb-1'>
                <Col xs={12}>
                {me?.role &&
                    <Button
                        className='pull-left'
                        color={fullStatsVisible ? 'success' : 'default'}
                        onClick={toggleFullStatsVisible}
                    >
                        Ver estadísticas
                    </Button> }
                    <Button
                        className='pull-right'
                        color={autoRefreshing ? 'success' : 'default'}
                        onClick={toggleAutoRefreshing}
                    >
                        Auto-refresh
                    </Button>
                </Col>
            </Row>
            {device &&
            <>
                {/*    -----  Stats graph  -------   */}
                {fullStatsVisible &&
                <Row>
                    <Col xs={12}>
                        <DeviceStats device={device}/>
                    </Col>
                </Row>
                }
                <Row>
                    {me?.role && 
                        <>
                            <Col lg={3} md={6} xs={12}>
                                <TunnelToggleCard type={'ssh'} customProp={customProp} device={device}/>
                            </Col>
                        
                            <Col lg={3} md={6} xs={12}>
                                <TunnelToggleCard type={'vnc'} customProp={customProp} device={device}/>
                            </Col>
                        </>   
                    }

                    {/*    -----  TEMPERATURA  -------   */}
                    <Col lg={3} md={6} xs={12}>
                        <TemperatureCard device={device}/>
                    </Col>

                    {/*    -------  ALMACENAMIENTO  -------   */}
                    <Col lg={3} md={6} xs={12}>
                        <StorageCard device={device}/>
                    </Col>

                </Row>

                {/*    -------  COMANDOS  -------   */}
                {me?.role && <CommandsRow device={device} customProp={customProp}/> }

                <SSHTunnelsRow device={device} onChange={reload}/>

                <Row>

                    {/*    -----  APPS  -------   */}
                    <Col lg={6} xs={12}>
                        <AppsTableCard device={device} customProp={customProp}/>
                    </Col>

                    {/*    -----  GENERAL INFO  -------   */}
                    <Col lg={3} xs={12} className='h-100'>
                        <DeviceInfoCard device={device}/>
                    </Col>

                    {/*    -----  IPS  -------   */}

                    <Col lg={3} xs={12}>
                        <IpAddressesCard device={device}/>
                        {me?.role &&
                        <Row className='m-0 p-0'>
                            <Col>
                                <Button
                                    color='danger'
                                    className='pull-left'
                                    onClick={startDeleting}
                                >
                                    Eliminar dispositivo
                                </Button>
                            </Col>
                        </Row> }
                    </Col>

                </Row>

                <Row>
                    {me?.role &&
                    <Col xs={12} lg={9}>
                        <CommentsSection cassette={device.cassette} deviceID ={device.id} theDevice={device} refresh={reload}/>
                    </Col> }
                    <Col xs={12} lg={3}>
                        <WifiListCard device={device} />
                    </Col>
                </Row>

                {/* Commented Temporally */}
                {me?.role && <Row>
                    <Col>
                        <ConsoleAsync
                            device={device}
                        ></ConsoleAsync>
                    </Col>
                </Row> }

            </>
            }

            {deleting ?
                <ReactBSAlert
                    warning
                    style={{display: "block", marginTop: "-100px"}}
                    title="¿Estás seguro?"
                    onConfirm={deleteDevice}
                    onCancel={stopDeleting}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sí, borrar!"
                    cancelBtnText="Cancelar"
                    showCancel
                    btnSize=""
                >
                    No vayas a regarla. Si hay un dispositivo conectado con este serial ya no podremos acceder a el
                    nunca más en la vida.
                </ReactBSAlert>
                : null}

            {editingName ?
                <ReactBSAlert
                    input
                    style={{display: "block", marginTop: "-100px"}}
                    title="Cambiar nombre"
                    defaultValue={device.alias}
                    onConfirm={editName}
                    onCancel={stopEditingName}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Guardar"
                    cancelBtnText="Cancelar"
                    showCancel
                >
                    Escribe el nuevo nombre
                </ReactBSAlert>
                : null}

        </div>
    );
};

export default DeviceInfo;
