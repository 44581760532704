import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import moment from 'moment';

import 'moment/locale/es' 
import "assets/css/nucleo-icons.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.0.0";
import 'react-dates/lib/css/_datepicker.css';
import "react-notification-alert/dist/animate.css";
import "assets/scss/iot-front.scss";

moment.locale('es');

ReactDOM.render( <App />,
    document.getElementById("root")
);
