import _ from 'lodash';
import {dashSGroups} from "./deviceUtils";

export const releaseArrayToAppArray = (releases)=>{
    return _.reduce(releases, (acc, release)=>{
        const appIndex = _.findIndex(acc, (app)=>app.id===release.application.id);

        if(appIndex!==-1)
            acc[appIndex].releases.push(release);
        else
            acc.push({...release.application, releases:[release]});

        return acc;
    },[]);

};


export const appsTableFromDevice=(device)=>{
    if(!device) return [];

    let apps = [];
    if(device.deviceReleases && device.deviceReleases.length)
        apps = device.deviceReleases.map(deviceRelease=>
            ({...deviceRelease.release.application, configured: deviceRelease.release.version })
        );

    if(device.reportedDeviceReleases && device.reportedDeviceReleases.length){
        _.forEach(device.reportedDeviceReleases, (installedRelease)=>{
            const app = _.find(apps, (app)=>app.name===installedRelease.name);
            if(app)
                app.installed= installedRelease.version;
            else
                apps.push({name: installedRelease.name, installed: installedRelease.version});
        });
    }

    return apps;
};

export const releaseSelectSGroups=[
    'release_read',
    'release_read_application',
        'application_read'
];

export const releaseListSGroups=[
    'release_read',
    'release_read_application',
        'application_read',
    'release_read_device_releases',
        'device_release_read_device',
            ...dashSGroups
];
