import React, {useEffect, useState} from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import AuthLayout from "./layouts/Auth/Auth";
import AppLayout from "./layouts/App";
import apiConfig, {ApiContext} from "./utils/api/api-config";
import {Provider} from "react-redux";
import {createStore} from "redux";
import rootReducer from "./reducers";
import {createBrowserHistory} from "history";
import Api from 'tide-api';
import NotificationAlert from 'react-notification-alert';
import { setComponent as setNotifierComponent } from "./utils/Notifier";
import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator";
import 'react-dates/initialize';
import IotClientApi, {IotClientContext} from "./utils/api/IotClientApi";

const store =  createStore( rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const hist = createBrowserHistory();

const api = new Api({...apiConfig, reduxStore: store});
const iotClientApi = new IotClientApi();

const App = ()=>{

    const [, reRender]=useState(null);

    useEffect(()=>{
        iotClientApi.subscribe(IotClientApi.events.CONNECT, reRender);
        iotClientApi.subscribe(IotClientApi.events.DISCONNECT, reRender);
        return ()=>{
            iotClientApi.unsubscribe(IotClientApi.events.CONNECT, reRender);
            iotClientApi.unsubscribe(IotClientApi.events.DISCONNECT, reRender);
        }
    },[]);

    return(
        <Provider store={store} >
            <ApiContext.Provider value={api} >
                <IotClientContext.Provider value={iotClientApi} >

                    <NotificationAlert ref={setNotifierComponent} />

                    <Router history={hist} >
                        <Switch>
                            <Route path="/auth" component={AuthLayout} />
                            <Route path="/app" component={AppLayout} />
                            <Redirect from="/" to="/app/dashboard" />
                        </Switch>
                    </Router>

                    <LoadingIndicator/>
                </IotClientContext.Provider>
            </ApiContext.Provider>
        </Provider>
    );
};

export default App;
