import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Table, Button} from "reactstrap";
import {ApiContext} from "../../utils/api/api-config";
import {useSelector} from "react-redux";
import moment from "moment";
import {colorForDate, deviceListSGroups, deviceFullSGroups} from "../../utils/modelUtils/deviceUtils";
import {releaseSelectSGroups} from "../../utils/modelUtils/releaseUtils";
import Checkbox from "../../components/Checkbox/Checkbox";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import EntitySelect from "../../components/EntitySelect";
import './assets/DeviceList.scss';
import _ from 'lodash';
import {notify} from "../../utils/Notifier";
import useBoolean from "../../utils/hooks/useBoolean";
const appsSelectFilters = {sGroups: releaseSelectSGroups};
const customProp = 'DeviceInfo.device';
const addingAppLoadingId = 'DeviceInfo.devices.update';

const DeviceList = () => {

    const api = useContext(ApiContext);
    const [selectedApp, setSelectedApp] = useState(null);
    const [checkboxes, setCheckboxes] = useState(true);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [addingApp, startAddingApp, stopAddingApp] = useBoolean();

    useEffect(() => {
        api.devices.get({params: {pagination: false, 'order[serial]': 'ASC', sGroups: deviceListSGroups}});
    }, [api]);

    const devices=useSelector(({api})=>api.devices);

    const [newDevices, setNewDevices] = useState([]);

    useEffect(() => {
        if(!!devices){
            setNewDevices(devices);
        }
    }, [devices]);

    const addApp = useCallback(() => {
        if (!selectedApp) return notify.error('Debes seleccionar una aplicación.');

        newDevices.map((deviceInfo) => {
            if (deviceInfo.isChecked) {
                const deviceReleases = _.map(deviceInfo.deviceReleases, 'id');
                const sameApp = _.findIndex(deviceInfo.deviceReleases, (deviceRelease) => deviceRelease.release.application.id === selectedApp.application.id);
                deviceInfo.isChecked = false;

                if (sameApp !== -1) {
                    deviceReleases[sameApp] = {release: selectedApp.id, id: deviceReleases[sameApp]};
                    notify.info('El dispositivo con nombre ' + deviceInfo.alias + ' ya tiene el software instalado.');
                } else {
                    deviceReleases.push({release: selectedApp.id, application: selectedApp.application.id});
                    notify.success('Se instalo correctamente el software en el dispositivo ' + deviceInfo.alias);
                }

                api.devices.update({
                    id: deviceInfo.id,
                    params: {deviceReleases, sGroups: deviceFullSGroups},
                    customProp,
                    loadingId: addingAppLoadingId
                })
                    .then(() => {
                        stopAddingApp();
                        api.devices.get({params: {pagination: false, 'order[serial]': 'ASC', sGroups: deviceListSGroups}});
                    });
            }
            return null
        })
        setCheckboxes(true);
        setSelectedApp(null);
        setBtnDisabled(true);
    }, [api, selectedApp, stopAddingApp, newDevices]);

    //ORDER BY SECTIONS FUNCTION
    const orderByHandler = (e) => {
        if (e.target.dataset.section === 'company') {
            setNewDevices(_.orderBy(devices, (device) => device.project.company.name.toLowerCase(), ['asc']));
        } else if (e.target.dataset.section === 'project') {
            setNewDevices(_.orderBy(devices, (device) => device.project.name.toLowerCase(), ['asc']));
        } else {
            setNewDevices(_.orderBy(devices, ['' + e.target.dataset.section + ''], ['asc']));
        }
    };

    //GET CHECKBOX VALUE
    const checkboxHandler = (id, booleanVal) => {
        newDevices.map((device) => {
            if (device.serial === id) {
                device.isChecked = booleanVal;
                setCheckboxes(false);
            }
            return null
        });

        const valueCheckboxes = valueAllCheckboxesHandler();
        if (valueCheckboxes === true) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    };

    //CHECK THE VALUE OF ALL CHECKBOXES
    const valueAllCheckboxesHandler = () => {
        for (let i = 0; i < newDevices.length; i++) {
            if (newDevices[i].isChecked === true) return false
        }
        return true
    }

    return (
        <div className='content DeviceList'>
            <Table striped>
                <thead>
                <tr>
                    <td/>
                    <td data-section='serial' className='category-column' onClick={orderByHandler}>Serial</td>
                    <td data-section='alias' className='category-column' onClick={orderByHandler}>Nombre</td>
                    <td data-section='connectionDate' className='category-column' onClick={orderByHandler}>Última
                        conexión
                    </td>
                    <td data-section='company' className='category-column' onClick={orderByHandler}>Compañía</td>
                    <td data-section='project' className='category-column' onClick={orderByHandler}>Proyecto</td>
                </tr>
                </thead>
                <tbody>
                {newDevices.map(device =>
                    <tr key={device.id}>
                        <td><Checkbox theSerial={device.serial}
                                      getDeviceID={checkboxHandler}
                                      resetCheckboxes={checkboxes}/></td>
                        <td>{device.serial}</td>
                        <td>{device.alias}</td>
                        <td style={{color: colorForDate(device.connectionDate)}}>
                            {moment(device.connectionDate).format('DD/MM/YYYY HH:mm:ss')}
                        </td>
                        <td>{device.project ? device.project.company.name : 'Sin compañía'}</td>
                        <td>{device.project ? device.project.name : 'Sin proyecto'}</td>
                    </tr>
                )}
                </tbody>
            </Table>
            <div className='list-install-software'>
                <button className='btn' onClick={startAddingApp} disabled={btnDisabled}>Instalar Software</button>
            </div>


            {addingApp ?
                <Modal isOpen>
                    <ModalHeader>Instalar Software</ModalHeader>
                    <ModalBody>
                        <h5>Has seleccionado los siguientes dipositivos:</h5>
                        <ul>
                            {newDevices.map(deviceSelected => {
                                    return (
                                        deviceSelected.isChecked &&
                                        <li key={deviceSelected.id} className='modal-devices'>
                                            <span className='modal-devices-section'>Serial:</span> {deviceSelected.serial},
                                            <span
                                                className='modal-devices-section'> Empresa:</span> {deviceSelected.project.company.name}
                                        </li>
                                    )
                                }
                            )}
                        </ul>
                        <EntitySelect
                            entity={'releases'}
                            filterBy={'search'}
                            additionalFilters={appsSelectFilters}
                            value={selectedApp}
                            onChange={setSelectedApp}
                            labelCreator={release => `${release.application.name} - ${release.version}`}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' onClick={stopAddingApp}>Cancelar</Button>
                        <Button color='success' onClick={addApp}>Agregar</Button>
                    </ModalFooter>
                </Modal> : null
            }
        </div>
    )
};

export default DeviceList;
