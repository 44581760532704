import React, {useContext, useState, useCallback} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {ApiContext} from "../../utils/api/api-config";
import {notify} from "../../utils/Notifier";
import {useSelector} from "react-redux";
import {releaseListSGroups} from "../../utils/modelUtils/releaseUtils";

const loadingId='@AddAppModal';
const AddAppModal=({onHide})=>{

    const api=useContext(ApiContext);
    const [file, setFile]=useState(null);

    const onFileChange=useCallback(({target})=>{
        if(target.files && target.files.length)
            setFile(target.files[0]);
        else
            setFile(null);
    },[]);

    const uploadApp = useCallback(()=>{
        if(!file)
            return notify.info('Debes seleccionar un archivo.');
        api.releases.create({params:{sGroups: releaseListSGroups}, files:{file}, loadingId})
            .then(onHide);

    },[api, file, onHide]);

    const loading = !!useSelector(({loadingIds})=>loadingIds[loadingId]);

    return (
       <Modal isOpen={true} toggle={onHide}>
           <ModalHeader toggle={onHide} tag='h4'>
               Nueva aplicación
           </ModalHeader>
           <ModalBody>
               <p>Sube un zip con tu aplicación. Toda la información se obtendrá de su package.json</p>
               <div>
                   <input type='file' className='form-control' onChange={onFileChange}/>
               </div>
           </ModalBody>
           <ModalFooter>
               <Button color='danger' disabled={loading} onClick={onHide}>Cancelar</Button>
               <Button color='success' disabled={!file || loading} onClick={uploadApp}>Guardar</Button>
           </ModalFooter>
       </Modal>
    );
};

export default AddAppModal;
