import React from 'react';
import {NavLink} from "react-router-dom";
import {Card} from "reactstrap";
import './assets/DevicePill.scss';
import {colorForDate} from "../../utils/modelUtils/deviceUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArchway,
    faCubes,
    faDesktop,
    faHeartBroken,
    faSimCard,
    faThermometerFull,
    faMicrophone
} from "@fortawesome/free-solid-svg-icons";

const DevicePill=({device})=>{

    const backgroundColor = colorForDate(device.connectionDate);

    const stat=(device.lastStat && device.lastStat.data) || {};

    const temperatureHigh = stat.temperature && stat.temperature > 58;
    const lowSpace = stat.disk && stat.disk.total && stat.disk.free && (stat.disk.free/stat.disk.total)<0.3;
    const throttled = stat.throttledStatus && stat.throttledStatus!=='0x0' && stat.throttledStatus !== 'Not a Raspberry';

    return (
        <NavLink to={'/app/device/'+ device.serial} className={'DevicePill'}>
            <Card className={'device-card'}>
                <div className='color' style={{backgroundColor}} />
                {device.alias ?
                    <>
                        <span className='name'>{device.alias}</span><br/>
                        <span className='serial'>{device.serial}</span>
                    </>
                    :
                    <>
                        <span className='name'>{device.serial}</span><br/>
                        <span className='serial'>Dispositivo sin nombre</span>
                    </>
                }
                <div className='badges'>
                    {!!temperatureHigh && <span className='badge' title='Temperatura alta'><FontAwesomeIcon icon={faThermometerFull} /></span>}
                    {lowSpace && <span className='badge storage' title='Poco almacenamiento'><FontAwesomeIcon icon={faSimCard} /></span>}
                    {throttled && <span className='badge throttle' title='El CPU está alentado'><FontAwesomeIcon icon={faHeartBroken} /></span>}
                    {device.appsMatch===false && <span className='badge appsMismatch' title='Las aplicaciones instaladas no coinciden'><FontAwesomeIcon icon={faCubes} /></span>}
                    {device.ssh && <span className='badge good ssh' title='SSH activo'><FontAwesomeIcon icon={faArchway} /></span>}
                    {device.vnc && <span className='badge good ssh' title='VNC activo'><FontAwesomeIcon icon={faDesktop} /></span>}
                    {device.statLogsEnabled && <span className='badge statLogsEnabled' title='Grabando estadísticas'><FontAwesomeIcon icon={faMicrophone}/></span>}
                </div>
            </Card>
        </NavLink>
    );
};

export default DevicePill;
