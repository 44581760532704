import React,{useCallback, useState, useContext} from 'react';
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Accordion} from "../../components/Accordion";
import './assets/AddDeviceModal.scss';
import EntitySelect from "../../components/EntitySelect";
import useCallbackCreator from "../../utils/hooks/useCallbackCreator";
import {ApiContext} from "../../utils/api/api-config";
import {notify} from "../../utils/Notifier";
import {useSelector} from "react-redux";
import {deviceListSGroups} from "../../utils/modelUtils/deviceUtils";


const creatingLoadingId='@AddDeviceModal.devices.create';
const entitySelectFilters={sGroups:deviceListSGroups};
const AddDeviceModal=({onHide, project, didAddDevice})=>{

    const api=useContext(ApiContext);

    const selectLabelCreator=useCallback((device)=>`${device.alias} - ${device.serial} - Company:${device.project.company.name} Project: ${device.project.name}`,[]);

    const [selectedDevice, setSelectedDevice]=useState(null);
    const [newDevice, setNewDevice]=useState({alias:'', serial:'', project: project.id});

    const changeNewDevice=useCallbackCreator((prop, {target})=>{
        setNewDevice({...newDevice, [prop]:target.value});
    },[newDevice]);

    const addDeviceToProject=useCallback((device)=>{
        onHide();
        api.devices.update({id:device.id, params:{project:project.id}})
            .then(didAddDevice);
    },[project, api, didAddDevice, onHide]);

    const createDeviceHandler=useCallback(()=>{
        if(!newDevice.serial)
            return notify.error('Debes agregar un serial.');
        api.devices.create({params:newDevice, loadingId:creatingLoadingId})
            .then(()=>{
                if(didAddDevice) didAddDevice();
                onHide();
            });
    },[newDevice, api, didAddDevice, onHide]);

    const addDeviceHandler=useCallback(()=>{
        if(!selectedDevice) return notify.error('Debes seleccionar un dispositivo.');
        addDeviceToProject(selectedDevice);
    },[selectedDevice, addDeviceToProject]);

    const loadingCreate=!!useSelector(({loadingIds})=>loadingIds[creatingLoadingId]);

    return (
        <Modal className='AddDeviceModal' isOpen toggle={onHide}>
            <ModalHeader tag='h4' toggle={onHide}>
                Agregar dispositivo al proyecto {project.name}
            </ModalHeader>
            <ModalBody>
                <Accordion>
                    <Accordion.Item>
                        <Accordion.Header>
                            Buscar un dispositivo
                        </Accordion.Header>
                        <Accordion.Body className={'select-device-card'}>
                            <EntitySelect
                                entity='devices'
                                filterBy={'search'}
                                labelCreator={selectLabelCreator}
                                value={selectedDevice}
                                onChange={setSelectedDevice}
                                additionalFilters={entitySelectFilters}
                            />
                            <Button color='success' onClick={addDeviceHandler}>Agregar</Button>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item>
                        <Accordion.Header>
                            Crear un dispositivo nuevo
                        </Accordion.Header>
                        <Accordion.Body>
                            <Label>Nombre</Label>
                            <input className='form-control' value={newDevice.alias} onChange={changeNewDevice('alias')}/>
                            <Label>Serial</Label>
                            <input className='form-control' value={newDevice.serial} onChange={changeNewDevice('serial')}/>
                            <Button color='success' onClick={createDeviceHandler} disabled={loadingCreate}>Crear</Button>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={onHide}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddDeviceModal;
