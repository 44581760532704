
export const prepareSSHTunnelForServer = (params)=>{
    const prepared = {
        ...params,
        localPort: Number(params.localPort),
        dstPort: Number(params.dstPort),
    };
    if(prepared.localPort > 65535 || prepared.dstPort > 65535 )
        throw new Error('¡Hey! What are you talking about? There are only 65535 ports in a computer.');

    if(!prepared.localPort || !prepared.dstPort)
        throw new Error('The ssh tunnel requires a remote and a local port.');
    return prepared;
};

