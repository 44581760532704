import React from 'react';
import {Card, CardBody, CardFooter, CardTitle, Col, Row} from "reactstrap";

const TemperatureCard = ({device}) => {

    const stat = (device && device.lastStat && device.lastStat.data) || {};

    return (
        <Card className="card-stats card-action TemperatureCard">
            <CardBody>
                <Row>
                    <Col xs="5">
                        <div className={"info-icon text-center icon-info"}>
                            <i className="tim-icons icon-sound-wave"/>
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="numbers">
                            <p className="card-category">Temperatura</p>
                            <CardTitle tag="h3">{stat.temperature? (stat.temperature+' °C'):'No disponible'}</CardTitle>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats">
                    <i className="tim-icons icon-alert-circle-exc"/> OK
                </div>
            </CardFooter>
        </Card>
    );
};

export default TemperatureCard;
