import React, {useState, useCallback, useContext} from 'react';
import './NewTunnelModal.scss';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import Switch from "react-bootstrap-switch";
import classNames from 'classnames';
import useCallbackCreator from "../../utils/hooks/useCallbackCreator";
import {notify} from "../../utils/Notifier";
import EntitySelect from "../../components/EntitySelect";
import IotClientApi, {IotClientContext} from "../../utils/api/IotClientApi";
import {ApiContext} from "../../utils/api/api-config";
import {deviceFullSGroups} from "../../utils/modelUtils/deviceUtils";
import {prepareSSHTunnelForServer} from "../../utils/modelUtils/sshTunnelUtils";

const NewTunnelModal = ({onClose, onCreate, fixedDevice, customProp}) => {

    const [isOnDevice, setIsOnDevice] = useState(!!fixedDevice);
    const [isReverse, setIsReverse] = useState(false);
    const [device, setDevice] = useState(null);
    const [form, setForm] = useState({localPort:'', dstPort:'', hostname:'',remoteUser:'', alias:''});

    const clientApi = useContext(IotClientContext);
    const api = useContext(ApiContext);

    const onFormChange = useCallbackCreator((name, {target})=>{
        setForm(form=>{
            form = {...form, [name]:target.value};
            return form;
        });
    },[]);
    const createTunnel = useCallback(()=>{

        let params = {
            ...form,
            isReverse,
            direction: isReverse?
                IotClientApi.constants.SSH_TUNNEL_DIR_LOCAL_TO_REMOTE:
                IotClientApi.constants.SSH_TUNNEL_DIR_REMOTE_TO_LOCAL,
            hostname: form.hostname||undefined,
            remoteUser: form.remoteUser||undefined,
        };

        try {
            params = prepareSSHTunnelForServer(params);
        }
        catch (e) {
            return notify.error(e.message);
        }

        if(isOnDevice && !device && !fixedDevice )
            return notify.error('Para abrir un túnel en un dispositivo, selecciona primero el dispositivo. ¡SI NO SABES QUÉ ES ESTO NO LO HAGAS!');
        else if(isOnDevice)
            params.device = fixedDevice? fixedDevice.id : device.id;

        if(isOnDevice){
            params.sGroups = deviceFullSGroups;
            api.sshTunnels.create({params, customProp})
                .then(onCreate)
        }
        else {
            clientApi.socketCall({path: IotClientApi.paths.sshCreateTunnel, params})
                .then(response => {
                    if (response.success)
                        return onCreate();
                    else if (response.error)
                        return notify.error('Error creando túnel: ' + response.detail);

                    notify.error('Error desconocido al crear túnel');
                });
        }

    },[api, form, clientApi, onCreate, isOnDevice, isReverse, device, fixedDevice, customProp]);

    return (
        <Modal isOpen className={"NewTunnelModal"} onClosed={onClose} >
            <ModalHeader>Nuevo túnel</ModalHeader>
            <ModalBody>
                <div className='text-center'>
                    <span className={classNames('switch-tag', !isOnDevice&&'active')}>Local</span>
                    <Switch value={isOnDevice} onChange={(s,val)=>setIsOnDevice(val)} onText='D' offText='L'/>
                    <span className={classNames('switch-tag', isOnDevice&&'active')}>En dispositivo</span>
                </div>
                <div className='text-center'>
                    <span className={classNames('switch-tag', !isReverse&&'active')}>Normal</span>
                    <Switch value={isReverse} onChange={(s,val)=>setIsReverse(val)} onText='R' offText='N'/>
                    <span className={classNames('switch-tag', isReverse&&'active')}>Reverse</span>
                </div>

                {isOnDevice && !fixedDevice &&
                <EntitySelect
                    entity={'devices'}
                    filterBy={'search'}
                    value={device}
                    onChange={setDevice}
                    labelCreator={device=>`${device.serial}`}
                />}

                {isOnDevice && fixedDevice &&
                    <p className='fixed-device'>{fixedDevice.serial}</p>}

                <input className='form-control' placeholder='Puerto local*' title='Puerto local' value={form.localPort} onChange={onFormChange('localPort')}/>
                <input className='form-control' placeholder='Puerto remoto*' title='Puerto remoto' value={form.dstPort} onChange={onFormChange('dstPort')}/>
                <input className='form-control' placeholder='Servidor ( iot.tide.mx )' title='Servidor' value={form.hostname} onChange={onFormChange('hostname')}/>
                <input className='form-control' placeholder='Usuario SSH ( iot )' title='Usuario SSH' value={form.remoteUser} onChange={onFormChange('remoteUser')}/>
                <input className='form-control' placeholder='Alias' title='Alias' value={form.alias} onChange={onFormChange('alias')}/>

                {!!form.localPort && !!form.dstPort &&
                    <p>
                        El puerto {form.localPort} de{isOnDevice?'l dispositivo':' tu computadora'} {isReverse?'será expuesto en':'expondrá'} el puerto {form.dstPort} del servidor {form.hostname}
                    </p>
                }
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={onClose}>Cancelar</Button>
                <Button color='success' onClick={createTunnel}>Crear</Button>
            </ModalFooter>

        </Modal>
    );
};

export default NewTunnelModal;
