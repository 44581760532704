import React, {useState, useEffect} from 'react';
import './Checkbox.scss';
import className from 'classnames';

const Checkbox = ({theSerial, getDeviceID, isModifier, resetCheckboxes})=>{

    const [checkedValue, setCheckedValue] = useState(false);

    useEffect(() => {
        if (resetCheckboxes === true) {
            setCheckedValue(false);
        }
    }, [resetCheckboxes]);

    const checkHandler = (event) => {
        getDeviceID(event.target.id, event.target.checked);
        setCheckedValue(!checkedValue);
    }

    return(
        <div className="CheckBoxContainer">
            <label htmlFor={`${theSerial}` } className="Check">
                <input id={`${theSerial}`}  onChange={checkHandler} type="Checkbox" checked={checkedValue}/>
                <div className={className("Box",isModifier?"modifier":"square")}/>
            </label>
        </div>
    )
};
export default Checkbox;