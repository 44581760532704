import moment from 'moment';

const aMomentAgoFormatter =  ( date )=>{

    const now = moment();
    const months = now.diff(date, 'months' );
    const days = now.clone().subtract(months, 'months').diff(date, 'days' );

    if( months || days ) {

        const monthsString = months? ` ${months} mes${months !== 1?'es':''}`:'';
        const daysString = days? ` ${days} día${days !== 1?'s':''}`:'';

        return `hace${monthsString}${daysString}`;
    }

    const hours = now.clone().diff(date, 'hours' );

    if( hours )
        return `hace ${hours} hora${hours !== 1?'s':''}`;

    const minutes = now.clone().diff(date, 'minutes' );
    if( minutes )
        return `hace ${minutes} minuto${minutes !== 1?'s':''}`;

    const seconds = now.clone().diff(date, 'seconds' );
    return `hace ${seconds} segundo${seconds !== 1?'s':''}`;
};

export default aMomentAgoFormatter;
