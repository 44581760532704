import React, {useState, useContext, useEffect, useCallback} from 'react';
import './assets/LocalClient.scss';
import IotClientApi, {IotClientContext} from "../../utils/api/IotClientApi";
import {notify} from "../../utils/Notifier";
import NewTunnelModal from "../../components/NewTunnelModal/NewTunnelModal";
import useBoolean from "../../utils/hooks/useBoolean";
import TunnelCard from "../../components/TunnelCard/TunnelCard";
import _ from 'lodash';


const LocalClient = () => {

    const clientApi = useContext(IotClientContext);
    const [addingTunnel, startAddingTunnel, stopAddingTunnel] = useBoolean();

    const [tunnels, setTunnels]=useState([]);
    const loadTunnels = useCallback(()=>{
        clientApi.socketCall({path: IotClientApi.paths.sshTunnels}).then((response)=>{
            if(response.success)
                setTunnels(response.data);
            else if(response.error)
                notify.error('Socket error: '+response.detail);
            else
                notify.error('Unknown socket error');
        });
    },[clientApi]);

    useEffect(loadTunnels, [loadTunnels]);

    const handleCreated = useCallback(()=>{
        loadTunnels();
        stopAddingTunnel();
    },[loadTunnels, stopAddingTunnel]);

    useEffect(()=>{
        const handlers = {
            [IotClientApi.events.TUNNEL_CREATED]:(tunnel)=> setTunnels( tunnels=>[...tunnels, tunnel] ),//Append new tunnel
            [IotClientApi.events.TUNNEL_CHANGED]:(tunnel)=> setTunnels( tunnels=>tunnels.map(t=>t.id===tunnel.id?tunnel:t)),//Replace tunnel with changed one
            [IotClientApi.events.TUNNEL_DELETED]: ({id})=>setTunnels((tunnels)=>_.filter(tunnels, t=>t.id!==id)),//Remove tunnel from array
            [IotClientApi.events.TUNNEL_LIST_CHANGED]:(tunnels)=> setTunnels(tunnels),//Replace the whole tunnel list
            [IotClientApi.events.TUNNEL_ERROR]:({tunnel, error})=> notify.error(`Tunnel ${tunnel.localPort}:${tunnel.hostname}:${tunnel.dstPort} had error: ${error}`, {autoDismiss:15}),
        };
        //Subscribe to all events
        _.forEach( handlers, (handler, evt)=>clientApi.subscribe(evt, handler ));
        //Unsubscribe from all events
        return ()=>_.forEach( handlers, (handler, evt)=>clientApi.unsubscribe(evt, handler ));
    });

    return (
        <div className={"content LocalClient"}>
            <h3 className='tunnel-title'>Túneles</h3>
            <button className='btn pull-right' onClick={startAddingTunnel}>Nuevo túnel</button>
            <div className='clearfix' />
            <div className='container-fluid'>
                <div className='row'>
                    {tunnels.map( tunnel=>
                        <div className='col-md-4 col-sm-6 col-12' key={tunnel.id}>
                            <TunnelCard tunnel={tunnel}/>
                        </div>
                    )}
                </div>
            </div>

            {addingTunnel &&
                <NewTunnelModal
                    onClose={stopAddingTunnel}
                    onCreate={handleCreated}
                />}

        </div>
    );
};

export default LocalClient;
