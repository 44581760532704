import React, {useState, useContext, useCallback, useEffect } from 'react';
import Modal from "reactstrap/es/Modal";
import {Label, ModalHeader} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import {ApiContext} from '../../utils/api/api-config';
import {notify} from "../../utils/Notifier";
import useInput from "../../utils/hooks/useInput";
import {useSelector} from "react-redux";

const AddUserModal =({onHide, entity, edit, title}) => {
    const username=useInput(edit? edit.username:'');
    const email=useInput(edit ? edit.email: '');
    const password = useInput('');
    const [role, setRole] = useState(edit?.role?.id || '0');
    const [company, setCompany] = useState(edit?.company?.id || '')
    const api = useContext(ApiContext);


    useEffect(() => {
        api.roles.get({params: {pagination: false}});
        api.companies.get();
    }, [api]);

    const validateRequiredFields = useCallback((params) => {
        if (!params.username) {
            notify.error('El username es requerido');
            return false;
        }
        if (!edit && !password.value) {
            notify.error('La contraseña es requerida');
            return false;
        }
        if (!params.email) {
            notify.error('El email es requerido');
            return false;
        }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(email.value)) {
            notify.error('Error en el correo electrónico');
            return false;
        }
        if (!role || role === '0') {
            notify.error('Selecciona un tipo de usuario');
            return false;
        }
        return true;
    }, [edit, email.value, password.value, role]);

    const roles = useSelector(({api}) => api.roles || []);
    const companies = useSelector(({api}) => api.companies || []);
    const user = (roles.map(rol => rol));

    const save= useCallback( () => {
        let params = {
            username:username.value.trim(),
            email:email.value.trim(),
            company: company||null,
        };
        if (validateRequiredFields(params)) {
            if (edit) {
                if(role !== 'null') {
                    params = {...params, role: role};
                    api[entity].update({id: edit.id, params}).then(onHide)
                } else {
                    params = {...params, role:null};
                    api[entity].update({id: edit.id, params}).then(onHide)
                }
            } else {
                params = {...params, password: password.value.trim()};
                if (role !== 'null') {
                    params = {...params, role: role};
                    api[entity].create({params}).then(onHide);
                } else {
                    api[entity].create({params}).then(onHide);
                }
            }
        }
    },[username.value, email.value, company, validateRequiredFields, edit, role, api, entity, onHide, password.value]);

    const handleChange = (e) => {
        setRole(e.target.value);
    };

    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
    };

    return(
        <Modal isOpen toggle={onHide}>
            <ModalHeader tag='h4' toggle={onHide}>
                {title}
            </ModalHeader>
            <ModalBody>
                <Label>Username</Label>
                <input {...username.bind} className='form-control'/>
                { edit ? null : <label>Contraseña</label>}
                { edit ? null :  <input {...password.bind} type='password' className='form-control'/>
                }
                <label>Email</label>
                <input {...email.bind} className='form-control'/>
                <br/>
                <select
                    onChange={(event => handleChange(event))}
                    className="form-control"
                    value={role}
                >
                    <option
                        key="key1"
                        value={'0'}
                    >
                        Tipo de usuario
                    </option>
                    <option
                        key="key2"
                        value={'null'}
                    >
                        Usuario normal
                    </option>
                    {
                        user.map((rol)=> <option key={rol.id} value={rol.id}> {rol.title} </option>)
                    }
                </select>
                <br/>
                <select
                    onChange={(event => handleCompanyChange(event))}
                    className="form-control"
                    value={company}
                >
                    <option
                        key="company1"
                        value={'0'}
                    >
                        Compañía
                    </option>
                    {
                        companies.map((company) => <option key={company.id} value={company.id}> {company.name} </option> )
                    }
                </select>
                <br />
                <button
                    className='pull-right btn btn-success'
                    onClick={save}
                >
                    Guardar
                </button>
            </ModalBody>
        </Modal>
    )
};

export default  AddUserModal;
