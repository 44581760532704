import React, {useState, useEffect, useCallback, useContext} from "react";
import classNames from "classnames";

import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
  Input
} from "reactstrap";
import {ApiContext} from "../../utils/api/api-config";
import {notify} from "../../utils/Notifier";
import "./AdminNavbar.scss";
import _ from "lodash";
import {connect} from "react-redux";
import useDebounce from "../../utils/hooks/useDebounce";

const AdminNavbar =(props)=>{

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [color, setColor] = useState('navbar-transparent');
  const [devicesList, setDevicesList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const searchValue = useDebounce(searchInput, 300);

  const api = useContext(ApiContext);


  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = useCallback(() => {
    if (window.innerWidth < 993 && collapseOpen)
      setColor("bg-white");
    else
      setColor('navbar-transparent');
  },[collapseOpen]);

  useEffect(()=>{
    window.addEventListener("resize", updateColor);
    return ()=>window.removeEventListener("resize", updateColor);
  },[updateColor]);

  // this function opens and closes the collapse on small devices
  const toggleCollapse = useCallback(() => {
    if (window.innerWidth < 993 && collapseOpen)
      setColor("bg-white");
    else
      setColor('navbar-transparent');
    setCollapseOpen(!collapseOpen);
  },[collapseOpen]);

  // this function is to open the Search modal
  const toggleModalSearch = useCallback(() => {
    setModalSearch(!modalSearch);
    setSearchInput('');
    setDevicesList([]);
  }, [modalSearch]);

  useEffect(() => {
    if (searchValue) {
      api.devices.get(
        {
          customProp: '_',
          params: {
            search: searchValue,
            itemsPerPage: 5
          }
        }
      ).then(response => {
        if (response.length > 0) {
          setDevicesList(response);
        } else {
          notify.error('No se encontraron coincidencias', {place: 'tc'});
          setDevicesList([]);
        }
      })
    } else {
      setModalSearch(false);
    }
  }, [setModalSearch, searchValue, api]);

  /**
   * Handle device selected in device list
   */
  const handleDeviceSelected = useCallback((deviceSelected) => {
    toggleModalSearch();
    props.history.push(`/app/device/${deviceSelected.serial}`);
  }, [props.history, toggleModalSearch]);

  return (
    <Navbar
      className={classNames("navbar-absolute AdminNavbar ", {
        [color]:
          props.location.pathname.indexOf("full-screen-map") === -1
      })}
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
            <Button
              className="minimize-sidebar btn-just-icon"
              color="link"
              id="tooltip209599"
              onClick={props.handleMiniClick}
            >
              <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
              <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209599"
              placement="right"
            >
              Cambiar barra lateral
            </UncontrolledTooltip>
          </div>
          <div
            className={classNames("navbar-toggle d-inline", {
              toggled: props.sidebarOpened
            })}
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={props.toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
            {props.brandText}
          </NavbarBrand>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav className="ml-auto" navbar>
            <InputGroup className="search-bar" tag="li">
              <Button
                color="link"
                data-target="#searchModal"
                data-toggle="modal"
                id="search-button"
                onClick={toggleModalSearch}
              >
                <i className="tim-icons icon-zoom-split" />
                <span className="d-lg-none d-md-block">Search</span>
              </Button>
              {
                modalSearch &&
                <>
                  <Input
                    placeholder="Ingresa el nombre o serial del dispositivo"
                    className="input-search input-border"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  {
                    devicesList.length > 0 &&
                    <ul role="menu" aria-hidden="true" className="dropdown-navbar dropdown-menu dropdown-menu-right show">
                      {_.map( devicesList, (device)=>
                        <li className="nav-link" key={device.id}>
                          <button
                            type="button"
                            role="menuitem"
                            className="nav-item dropdown-item pointer"
                            onClick={() => handleDeviceSelected(device)}
                          >
                            <span className="text-dark">{device.alias}</span> {device.serial}
                          </button>
                        </li>
                      )}
                    </ul>
                  }
                </>
              }
            </InputGroup>
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                nav
                onClick={e => e.preventDefault()}
              >
                <div className="photo">
                  <img alt="..." src={require("assets/img/default-avatar.png")} />
                </div>
                <b className="caret d-none d-lg-block d-xl-block" />
                <p className="d-lg-none">Perfil</p>
              </DropdownToggle>
              <DropdownMenu className="dropdown-navbar" right tag="ul">
                <DropdownItem divider tag="li" />
                <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={api.logout}>Salir</DropdownItem>
                </NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
            <li className="separator d-lg-none" />
            <div className="text-primary p-3">
              {
                props.me &&
                <h5>
                  {props.me.username}
                </h5>
              }
            </div>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = ( {api:{me}} )=>({me});
export default connect(mapStateToProps)(AdminNavbar);
