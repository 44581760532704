import React, {useEffect, useContext, useCallback, useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import DevicePill from "../../components/Devices/DevicePill";
import './assets/CompanyDevices.scss';
import useTideEntity from "../../utils/hooks/useTideEntity";
import {ApiContext} from "../../utils/api/api-config";
import {useSelector} from "react-redux";
import useBoolean from "../../utils/hooks/useBoolean";
import NameFormModal from "../../components/NameFormModal";
import ReactBSAlert from "react-bootstrap-sweetalert";
import useCallbackCreator from "../../utils/hooks/useCallbackCreator";
import AddDeviceModal from "./AddDeviceModal";
import {notify} from "../../utils/Notifier";

const CompanyDevices = ({match, history})=>{

    const id=Number(match.params.id);

    const api = useContext(ApiContext);
    const [company, loading, notFound] = useTideEntity('companies', id);

    const [removingCompany, startRemovingCompany, stopRemovingCompany]=useBoolean();
    const [editingCompany, startEditingCompany, stopEditingCompany]=useBoolean();

    const [addingProject, startAddingProject, stopAddingProject]=useBoolean();
    const [editingProject, setEditingProject]=useState(null);
    const [removingProject, setRemovingProject]=useState(null);
    const [addDeviceToProject, setAddDeviceToProject]=useState(null);

    const projectCrudCreator=useCallbackCreator((action)=>{
        const [type, index]=action.split('-');
        if(type==='remove') setRemovingProject(projects[index]);
        else if(type==='edit') setEditingProject(projects[index]);
    });

    const load=useCallback(()=>{
        api.projects.get({params:{company:id}});
    },[id, api]);

    useEffect(()=>api.clearProperty('projects'),[api, id]);

    useEffect(()=>load(),[load]);

    const createdProject=useCallback(()=>{
        load();
        stopAddingProject();
    },[load, stopAddingProject]);

    const projects = useSelector(({api})=>api.projects||[]);

    const deleteCompany=useCallback(()=>{
        stopRemovingCompany();
        api.companies.delete({id:company.id})
            .then(()=>history.push('/app/companies'));
    },[company, history, api, stopRemovingCompany]);

    const stopRemovingProject=useCallback(()=>setRemovingProject(null),[]);
    const stopEditingProject=useCallback(()=>setEditingProject(null),[]);

    const deleteProject=useCallback(()=>{
        api.projects.delete({id:removingProject.id});
        stopRemovingProject();
    },[removingProject, stopRemovingProject, api]);

    const stopAddingDevice =useCallback(()=>setAddDeviceToProject(null),[]);

    const startAddingDevice=useCallbackCreator((projectIndex)=>{
        const project=projects[projectIndex];
        if(!project) return notify.error('Pasó un error extraño. Avísale a Mark');
        setAddDeviceToProject(project);
    });

    return (
        <div className='content CompanyDevices'>
            <Row>
                <Col xs="12">
                    <h1 className='text-center'>
                        {company && ('Dispositivos de '+company.name)}
                        {loading && 'Cargando...'}
                        {notFound && 'Compañía no encontrada'}
                    </h1>
                </Col>
            </Row>

            {projects.map((project,i)=>
                <React.Fragment key={project.id}>
                    <Row>
                        <Col xs={12}>
                            <h2 className='project-title'>
                                <span>
                                    Proyecto - {project.name}
                                </span>
                                <br />
                                <div className='btn-container'>
                                    <Button color='danger' onClick={projectCrudCreator('remove-'+i)}><i className='tim-icons icon-trash-simple'/></Button>
                                    <Button color='info' onClick={projectCrudCreator('edit-'+i)}><i className='tim-icons icon-pencil'/></Button>
                                    <Button color='default' onClick={startAddingDevice(i)} >+</Button>
                                </div>
                                <div className='clearfix'/>
                                <hr/>
                            </h2>
                        </Col>
                    </Row>

                    <Row>
                        {project.devices.map(device=>
                            <Col xs={12} sm={6} md={3} lg={2} key={device.id}>
                                <DevicePill device={device}/>
                            </Col>
                        )}
                    </Row>
                </React.Fragment>
            )}

            {company&&
            <Row>
                <div className='end-spacer'/>
                <Col xs={12}>
                    <Button color='danger' onClick={startRemovingCompany}><i className='tim-icons icon-trash-simple'/></Button>
                    <Button color='info' onClick={startEditingCompany}><i className='tim-icons icon-pencil'/></Button>
                    <Button className='add-project-btn' onClick={startAddingProject}>Agregar proyecto</Button>
                </Col>
            </Row>}

            {addingProject?
                <NameFormModal
                    onHide={stopAddingProject}
                    onSave={createdProject}
                    entity={'projects'}
                    additionalProps={{company:company.id}}
                />
                :null}

            {editingCompany||editingProject?
                <NameFormModal
                    onHide={editingCompany?stopEditingCompany:stopEditingProject}
                    entity={editingCompany?'companies':'projects'}
                    edit={editingCompany?company:editingProject}
                    title={editingCompany?'Editar compañía':'Editar proyecto'}
                />
                :null}

            {removingCompany || removingProject?
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={removingCompany? deleteCompany:deleteProject}
                    onCancel={removingCompany? stopRemovingCompany:stopRemovingProject}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sí, borrar!"
                    cancelBtnText="Cancelar"
                    showCancel
                    btnSize=""
                >
                    ¡Adios amigos! Se va {removingCompany?'esta empresa':'este proyecto'} para siempre de los siempres.
                </ReactBSAlert>
            :null}

            {addDeviceToProject?
                <AddDeviceModal
                    project={addDeviceToProject}
                    onHide={stopAddingDevice}
                    didAddDevice={load}
                />
            :null}

        </div>
    );
};

export default CompanyDevices;
