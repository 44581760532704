import React, {useContext, useCallback} from 'react';
import './TunnelCard.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloud, faDesktop, faNetworkWired} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import IotClientApi, {IotClientContext} from "../../utils/api/IotClientApi";
import {notify} from "../../utils/Notifier";
import useCallbackCreator from "../../utils/hooks/useCallbackCreator";
import classNames from 'classnames';
import {ApiContext} from "../../utils/api/api-config";

const TunnelCard = ({tunnel, onChange, device}) => {

    const api = useContext(ApiContext);
    const clientApi = useContext(IotClientContext);
    const reverse = tunnel.isReverse || tunnel.direction === IotClientApi.constants.SSH_TUNNEL_DIR_LOCAL_TO_REMOTE;

    const tunnelDeviceOperation = useCallback((operation)=>{
       if(operation === IotClientApi.paths.sshDeleteTunnel)
           api.sshTunnels.delete({id:tunnel.id}).then(onChange);
    },[tunnel, api, onChange]);

    const handleTunnelOperation = useCallbackCreator((operation)=>{

        if(device)
            return tunnelDeviceOperation(operation);

        clientApi.socketCall({path:operation, params:{id:tunnel.id}})
            .then((resp)=>{
                if(!resp || !resp.success)
                    notify.error(resp && resp.detail?resp.detail:'Unknown error on socket call');
            })
            .catch((e)=>notify.error(e));

    },[clientApi, tunnel, tunnelDeviceOperation]);

    return (
        <div className={classNames('card TunnelCard', tunnel.alias && 'has-alias')}>
            <div className={'background ' +  (reverse&&'reverse') } />
            <div className='port'>
                <FontAwesomeIcon icon={device? faNetworkWired:faDesktop} className='icon' />
                <p>{tunnel.localPort}</p>
            </div>
            <div className='host'>
                {tunnel.alias && <p className='alias'>{tunnel.alias}</p>}
                <p>{tunnel.remoteUser}@{tunnel.hostname}</p>
                <div>
                    {!device&&/* For the moment the device's tunnels cannot be opened and closed, they are always open*/
                    <Button
                        onClick={handleTunnelOperation(tunnel.open?IotClientApi.paths.sshCloseTunnel:IotClientApi.paths.sshOpenTunnel)}
                        className={'btn-sm btn-simple '+(tunnel.open? 'btn-danger':'btn-success')} >
                        {tunnel.open?'Cerrar':'Abrir'}
                    </Button>}

                    <Button
                        onClick={handleTunnelOperation(IotClientApi.paths.sshDeleteTunnel)}
                        className={'btn-danger btn-sm btn-simple'}>
                        Borrar
                    </Button>
                </div>
            </div>
            <div className='port'>
                <FontAwesomeIcon icon={faCloud}  className='icon'/>
                <p>{tunnel.dstPort}</p>
            </div>
        </div>
    );
};

export default TunnelCard;
