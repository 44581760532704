import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";

const IpAddressesCard = ({device}) => {

    const stat = (device && device.lastStat && device.lastStat.data) || {};

    return (
        <Card className='IpAddressesCard'>
            <CardHeader>
                <CardTitle tag="h4">Direcciones ip</CardTitle>
            </CardHeader>
            <CardBody>
                <Table>
                    <tbody>
                    {stat.ipAddresses && stat.ipAddresses.length ?

                        stat.ipAddresses.map(ip => <tr key={ip}><td>{ip}</td></tr>)
                        :
                        <tr><td>Ninguna dirección ip</td></tr>
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default IpAddressesCard;
