import _ from 'lodash';
import moment from "moment";

export const groupDevicesByProject = (devices)=>{
    return _.reduce(devices, (acc, device)=>{
        const proIndex = _.findIndex(acc, (project)=>project.id===device.project.id);

        if(proIndex!==-1)
            acc[proIndex].devices.push(device);
        else
            acc.push({...device.project, devices:[device]});

        return acc;
    },[]);
};

export const groupDevicesByCompany = (devices)=>{
    return _.reduce(devices, (acc, device)=>{
        const proIndex = _.findIndex(acc, (company)=>company.id===device.company.id);

        if(proIndex!==-1)
            acc[proIndex].devices.push(device);
        else
            acc.push({...device.company, devices:[device]});

        return acc;
    },[]);
};


export const colorForDate=(date)=>{

    if(!date)
        return '#F00';

    const minutes = moment().diff(date, 'minutes');

    if(minutes<2)
        return '#00fa9a';
    if(minutes<30)
        return '#00fa9a';
    if(minutes<1440)
        return '#ecfa6a';
    if(minutes<7200)
        return '#fa6827';

    return '#F00';
};

export const dashSGroups=[
    'device_read_id',
    'device_read_alias',
    'device_read_serial',
    'device_read_apps_match',
    'device_read_last_stat',
        'stat_read_id',
        'stat_read_data',
    'device_read_connection_date',
    'device_read_company',
        'company_read',
    'device_read_stat_logs_enabled',
];

export const deviceFullSGroups=[
    'device_read',
    'device_read_alias',
    'device_read_serial',
    'device_read_config',
    'device_read_connection_date',
    'device_read_apps_match',
    'device_read_commands',
    'device_read_stat_logs_enabled',
    'device_read_last_stat',
        'stat_read_id',
        'stat_read_data',
    'device_read_company',
        'company_read_id',
        'company_read_name',
    'device_read_project',
        'project_read_id',
        'project_read_name',
    'device_read_reported_device_releases',
    'device_read_device_releases',
        'device_release_read',
        'device_release_read_release',
            'release_read',
            'release_read_application',
                'application_read',
    'device_read_ssh_tunnels',
        'ssh_tunnel_read',
    'cassette_read',
    'device_read_device_commands',
    'device_command_read'
];

export const deviceListSGroups=[
    'device_read',
    'device_read_last_stat',
        'stat_read',
    'device_read_project',
        'project_read',
        'project_read_company',
            'company_read',
    'device_read_reported_device_releases',
    'device_read_device_releases',
        'device_release_read',
        'device_release_read_release',
            'release_read',
            'release_read_application',
                'application_read'

];
