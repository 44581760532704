import {trimDecimals} from "./numbers";

const byteString = (bytes, maxDecimals=2)=>{

    if( bytes < 2048)// < 2k
        return bytes + ' B';

    if( bytes < 2097152)// < 2mb
        return trimDecimals(bytes/1024, maxDecimals) + ' KB';

    if( bytes < 2147483648)// < 2gb
        return trimDecimals(bytes/1048576, maxDecimals) + ' MB';

    return trimDecimals(bytes/1073741824, maxDecimals) + ' GB';
};

export default byteString;