import React, {useEffect, useContext, useMemo, useState, useCallback} from 'react';
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Button} from "reactstrap";
import AddAppModal from "./AddAppModal";
import useBoolean from "../../utils/hooks/useBoolean";
import {ApiContext} from "../../utils/api/api-config";
import {useSelector} from "react-redux";
import {releaseArrayToAppArray, releaseListSGroups} from "../../utils/modelUtils/releaseUtils";
import ReleaseDetailModal from "./ReleaseDetailModal";
import useCallbackCreator from "../../utils/hooks/useCallbackCreator";
import _ from 'lodash';
import './Applications.scss';


const Applications = ()=>{

    const api = useContext(ApiContext);

    const [addingApp, startAddingApp, stopAddingApp] = useBoolean();
    const [detailRelease, setDetailRelease] = useState(null);

    useEffect(()=>{
        api.releases.get({params:{pagination:false, sGroups:releaseListSGroups}});
    },[api]);

    const hideDetailRelease=useCallback(()=>setDetailRelease(null),[]);

    const showDetailRelease = useCallbackCreator((id)=>{
        const release=_.find(releases, release=>release.id===Number(id));
        setDetailRelease(release);
    },[]);

    const releases = useSelector(({api})=>api.releases||[]);
    const apps = useMemo(()=>releaseArrayToAppArray(releases),[releases]);

    return (
        <div className='content Applications'>
            <Row>
                <Col xs={12}>
                    <Button className='pull-right' onClick={startAddingApp}>Agregar aplicación</Button>
                </Col>
            </Row>
            <Row>
                {apps.map((app)=>
                    <Col md="6" key={app.id}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-category">Aplicación</h5>
                                <CardTitle tag="h3">{app.name}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {app.releases.map(release=>
                                    <Button
                                        key={release.id}
                                        className={"btn-round "+(!(release.deviceReleases && release.deviceReleases.length)?'no-devices-release':'')}
                                        color={release.deviceReleases && release.deviceReleases.length?'success':'primary'}
                                        onClick={showDetailRelease(release.id)}
                                    >
                                        {release.version} {release.deviceReleases && release.deviceReleases.length?'('+release.deviceReleases.length+' dispositivos)':''}
                                    </Button>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>

            {addingApp?
            <AddAppModal onHide={stopAddingApp}/>
            :null}

            {detailRelease?
            <ReleaseDetailModal release={detailRelease} onHide={hideDetailRelease}/>
            :null}
        </div>
    );
};

export default Applications;
