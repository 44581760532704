import React from 'react'
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import "./WifiListCard.scss";

export default function WifiListCard({device}) {

  const stat = (device && device.lastStat && device.lastStat.data) || {};

  return (
    <Card className='WifiListCard'>
      <CardHeader>
        <CardTitle tag="h4">WIFI</CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <tbody>
            {
              stat.wifiList && stat.wifiList.length
                ? stat.wifiList.map(wifi => 
                  (
                    <tr key={wifi.interface} className="border-bottom">
                      <td>
                        <ul className='list-group'>
                          <li className='item'>
                            <FontAwesomeIcon icon={faWifi} className="text-success"/>  {wifi.ssid ? wifi.ssid : ''}
                          </li>
                          <li className='item'>
                            <FontAwesomeIcon icon={faKey}/>  {wifi.pwd ? wifi.pwd : ''}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                : <tr><td>Sin información</td></tr>
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
