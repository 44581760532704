import React from 'react';
import {notify} from '../Notifier';
import urljoin from 'url-join';
import {STATE_ACTION_SEARCH_N_REPLACE} from "tide-api/src/constants_api";


const errorHandler = (e)=>{
    let message = 'Parece que hubo un error.';
    if(e.detail)
        message=<>{message}<br/>{e.detail}</>;
    return notify.error(message);
};

const config ={
    host: 'https://iot.tide.mx',
    commonPath:'api',
    onError:errorHandler,
    credentials:'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse:(r)=>r.data,
    getMetaDataFromResponse:(r)=>r.meta,
    endpoints:[
        'companies',
        'users',
        'roles',
        'me',
        'projects',
        'users',
        'sshTunnels',
        'stats',
        'comments',
        'cassettes',
        'deviceCommands',
        {
            name: 'devices',
            customMethods: {
                deleteCommands:function (id) {
                    return this.apiCall(
                        `/devices/${id}/commands`,
                        'devices',
                        {},
                        {stateAction:STATE_ACTION_SEARCH_N_REPLACE, method:'DELETE'})
                },
                executeCommand:function (id, command, params={}, config={}) {
                    return this.apiCall(
                        `/devices/${id}/command/${command}`,
                        'devices',
                        params,
                        {stateAction:STATE_ACTION_SEARCH_N_REPLACE, method:'PUT', ...config})
                },
                getPublic:function (serial, config={}) {
                    return this.apiCall(
                        `/devices/${serial}`,
                        'devices',
                        {},
                        {method:'GET',useCommonPath: false, ...config})
                },
            }
        },
        {
            name:'releases',
            customMethods:{
                downloadUrl:function(id){return urljoin(this.host,'api/releases/download', id+'')}
            }
        },
        {
            name: 'comment',
            customMethods: {
                getComments: function (id) {
                    return this.apiCall(`/cassettes/${id}/comments`, 'commentsGet', {}, {
                        method: 'GET'
                    });
                },
                putComments: function (id, params) {
                    return this.apiCall(`/comments/${id}`, 'commentsPut', {...params}, {
                        method: 'PUT'
                    });
                }
            },
        },
    ],
};

if (process.env.NODE_ENV === 'development')
    config.host = localStorage.host || 'http://iot-backend.localhost/';

export default config;

export const ApiContext = React.createContext(null);
