import React, {useEffect, useMemo, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import ReactECharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import {ApiContext} from '../../utils/api/api-config';
import {Row, Col, Input, Alert} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import './assets/DeviceStats.scss';
import {DateRangePicker} from 'react-dates';
import moment from 'moment';

const whiteAxis = {
    nameTextStyle: {
        color: 'white'
    },
    axisLine: {
        lineStyle: {
            color: 'white'
        }
    }
};

const tooltipFormatter = ({statChoice, topRamSeriesData, topCpuSeriesData})=>seriesData=>{
    const seriesInfo = seriesData[0];
    const title = statChoice==='cpu'?`Top CPU ${seriesInfo.value}% - ${seriesInfo.axisValueLabel}<br/>`:`Top RAM ${seriesInfo.value}%<br/>`;
    const topData = statChoice === 'cpu'?topCpuSeriesData:topRamSeriesData;
    if(!topData[seriesInfo.dataIndex]){
        return `${title} No disponible`;
    }
    return `${title} ${topData[seriesInfo.dataIndex].replace(/\n/g, '<br/>')}`;
};

const getOption = ({xAxisData, statChoice, startDate, endDate, ramSeriesData, cpuSeriesData, topRamSeriesData, topCpuSeriesData})=>({
    tooltip: {
        trigger: 'axis',
        position: pt => [pt[0], '10%'],
        formatter: tooltipFormatter({statChoice, topRamSeriesData, topCpuSeriesData})
    },
    title: {
        left: 'center',
        text: statChoice==='cpu'?'Uso de CPU':'RAM usada',
        textStyle: {
            color: 'rgba(255, 255, 255, 0.5)'
        }
    },
    toolbox: {
        feature: {
            dataZoom: {
                yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
        },
        iconStyle: {
            color: 'white'
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData,
        name: 'Fecha',
        ...whiteAxis
    },
    yAxis: {
        name: 'Uso (%)',
        type: 'value',
        min: 0,
        max: 100,
        axisLabel: {
            showMinLabel: true,
            showMaxLabel: true
        },
        splitNumber: 5,
        ...whiteAxis
    },
    dataZoom: [{
        type: 'inside',
        start: 0,
        end: 100
    }, {
        start: 0,
        end: 10,
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
        }
    }],
    series: [
        {
            name:'top',
            type:'line',
            smooth:true,
            symbol: 'none',
            sampling: 'average',
            itemStyle: {
                color: 'rgb(255, 70, 131)'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#ec250d'
                }, {
                    offset: 1,
                    color: '#fd5f93'
                }])
            },
            data: statChoice==='cpu'?cpuSeriesData:ramSeriesData
        }
    ]
});

const dateRangeParams = ({startDate, endDate}) => ({
    ...(startDate?{'updatedDate[after]':
                   startDate.clone()
                   .startOf('day')
                   .format('YYYY-MM-DD HH:mm:ss')}:{}),
    ...(endDate?{'updatedDate[before]':
                 endDate.clone()
                 .endOf('day')
                 .format('YYYY-MM-DD HH:mm:ss')}:{})
});

const DeviceStats = ({device}) => {
    const api = useContext(ApiContext);
    const [focus, setFocus] = useState(null);
    const [statChoice, setStatChoice] = useState('cpu');
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const stats = useSelector(({api: {stats=[]}})=>stats);

    useEffect(()=>{
        setLoading(true);
        api.stats.get({
            params: {
                ...dateRangeParams({startDate, endDate}),
                device: device.id,
                pagination: false,
                'order[updatedDate]': 'ASC'
            }
        }).finally(()=>setLoading(false));
    }, [api, startDate, endDate, device]);

    const xAxisData = useMemo(
        ()=>stats.map(s=>({
            value: s.updatedDate,
            textStyle: {color: 'white'}
        })),
        [stats]
    );
    const cpuSeriesData = useMemo(
        ()=>stats.map(s=>(s&&s.data&&s.data.cpu)?s.data.cpu:0),
        [stats]
    );
    const ramSeriesData = useMemo(
        ()=>stats.map(s=>
                      (s&&s.data&&s.data.ram&&s.data.ram.freeMemPercentage)?(100-s.data.ram.freeMemPercentage):0),
        [stats]
    );
    const topCpuSeriesData = useMemo(
        ()=>stats.map(s=>
                      (s&&s.data&&s.data.topCpu)?s.data.topCpu:''),
        [stats]
    );
    const topRamSeriesData = useMemo(
        ()=>stats.map(s=>
                      (s&&s.data&&s.data.topRam)?s.data.topRam:''),
        [stats]
    );

    const option = useMemo(
        ()=>getOption({
            xAxisData,
            statChoice,
            ramSeriesData,
            cpuSeriesData,
            topRamSeriesData,
            topCpuSeriesData,
            startDate,
            endDate
        }),
        [statChoice, startDate, endDate, xAxisData, ramSeriesData, cpuSeriesData, topCpuSeriesData, topRamSeriesData]
    );
    if(loading){
        return <Alert color='info'><FontAwesomeIcon icon={faSpinner} spin/> Cargando...</Alert>;
    }
    return (
        <div className='DeviceStats'>
            <div className='card stats-card'>
                <Row className='stat-controls'>
                    <Col md={6} xs={12}>
                        <Input type="select" value={statChoice} onChange={e=>setStatChoice(e.target.value)}>
                            <option value='cpu'>CPU</option>
                            <option value='ram'>RAM</option>
                        </Input>
                    </Col>
                    <Col md={6} xs={12}>
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            startDateId={'startDate'}
                            endDateId={'endDate'}
                            onDatesChange={({startDate, endDate})=>{
                                setStartDate(startDate);
                                setEndDate(endDate);
                            }}
                            isOutsideRange={d=>d.isAfter(moment())}
                            focusedInput={focus}
                            onFocusChange={setFocus}
                            startDatePlaceholderText={'Desde el'}
                            endDatePlaceholderText={'Hasta el'}
                            />
                    </Col>
                </Row>
                <ReactECharts option={option}/>
            </div>
        </div>
    );
};



export default DeviceStats;
