import React from 'react';
import {Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import './LoadingIndicator.scss';
import {useSelector} from "react-redux";

const LoadingIndicator=()=>{

    if(useSelector(s=>s.loading))
        return <Alert color='info' className='LoadingIndicator'><FontAwesomeIcon icon={faSpinner} spin /> Cargando...</Alert>;
    return null;
};

export default LoadingIndicator;
