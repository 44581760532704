import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Card, CardBody, CardFooter, CardTitle, Col, Row, Button} from "reactstrap";
import classNames from "classnames";
import {deviceFullSGroups} from "../../../utils/modelUtils/deviceUtils";
import {ApiContext} from "../../../utils/api/api-config";
import IotClientApi, {IotClientContext} from "../../../utils/api/IotClientApi";
import _ from "lodash";

const TunnelToggleCard = ({device, type, customProp = 'devices'}) => {

    const api = useContext(ApiContext);
    const iotClientApi = useContext(IotClientContext);
    const [localTunnel, setLocalTunnel] = useState(null);

    const tunnelOn = !!device[type];
    const port = device[type+'Port'];
    const ssh_user = device['sshUser'];
    const typeDevice= type;

    let sshUsr=(ssh_user? ssh_user:'timetide');
    let sshComando = 'ssh -p'+port+' '+sshUsr+'@localhost';

    const selectOnFocus = useCallback((e)=>{
        e.target.select();
    },[]);

    const findLocalTunnel = useCallback((tunnels)=>{
        if(!tunnelOn)
            return setLocalTunnel(null);

        const tunnel = _.find(tunnels, (tunnel)=>
            Number(tunnel.dstPort)===Number(port) &&
            (tunnel.direction === IotClientApi.constants.SSH_TUNNEL_DIR_REMOTE_TO_LOCAL || tunnel.isReverse === false));
        if(!tunnel)
            return setLocalTunnel(null);
        setLocalTunnel(tunnel);
    },[tunnelOn, port]);

    useEffect(()=>{
        if(!iotClientApi || !iotClientApi.ready()) return;

        iotClientApi.socketCall({path: IotClientApi.paths.sshTunnels})
            .then((res)=>{
                if(res.success)
                    findLocalTunnel(res.data);
            })
    },[iotClientApi, findLocalTunnel]);

    //New local tunnel created
    useEffect(()=>{
        if(!iotClientApi || !iotClientApi.ready()) return;

        const newTunnel = tunnel=>findLocalTunnel([tunnel] );
        iotClientApi.subscribe(IotClientApi.events.TUNNEL_CREATED, newTunnel );
        iotClientApi.subscribe(IotClientApi.events.TUNNEL_CHANGED, newTunnel );
        return ()=>{
            iotClientApi.unsubscribe(IotClientApi.events.TUNNEL_CREATED, newTunnel );
            iotClientApi.unsubscribe(IotClientApi.events.TUNNEL_CHANGED, newTunnel );
        }
    },[findLocalTunnel, iotClientApi]);

    //Local tunnel deleted
    useEffect(()=>{
        if(!iotClientApi || !iotClientApi.ready()) return;

        const removeTunnel = ({id})=>{
            if(localTunnel && id===localTunnel.id)
                setLocalTunnel(null);
        };
        iotClientApi.subscribe(IotClientApi.events.TUNNEL_DELETED, removeTunnel );
        return ()=>iotClientApi.unsubscribe(IotClientApi.events.TUNNEL_DELETED, removeTunnel );
    },[localTunnel, iotClientApi]);

    //Create, open and close local tunnel to match remote one
    const toggleLocalTunnel = useCallback(()=>{
        if(localTunnel && localTunnel.open)
            iotClientApi.socketCall({path: IotClientApi.paths.sshDeleteTunnel, params:{id:localTunnel.id}});
        if(localTunnel)
            iotClientApi.socketCall({path: IotClientApi.paths.sshOpenTunnel, params:{id:localTunnel.id}});
        else
            iotClientApi.socketCall({path: IotClientApi.paths.sshCreateTunnel,
                params:{
                    localPort: port,
                    dstPort: port,
                    direction: IotClientApi.constants.SSH_TUNNEL_DIR_REMOTE_TO_LOCAL,
                    alias: `Auto-tunnel for ${device.alias} (${device.serial})`
            }});
    },[localTunnel, iotClientApi, device, port]);

    const toggleProp = useCallback(()=>{

        api.devices.update({id:device.id, params:{[type]:!tunnelOn, sGroups:deviceFullSGroups}, customProp});
        if(tunnelOn && localTunnel && localTunnel.open)
            toggleLocalTunnel();

    },[api, tunnelOn, customProp, type, toggleLocalTunnel, device, localTunnel]);


    return (
        <Card className="card-stats card-action TunnelToggleCard">
            <CardBody>
                <Row>
                    <Col xs="5">
                        <div onClick={toggleProp}
                             className={classNames("info-icon text-center", tunnelOn ? "icon-success" : "icon-warning")}>
                            <i className={classNames("tim-icons", tunnelOn ? "icon-bulb-63" : 'icon-button-power')}/>
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="numbers">
                            <p className="card-category">Tunnel</p>
                            <CardTitle tag="h3">{type.toUpperCase()}</CardTitle>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats">
                    {tunnelOn ?
                        <div>
                            <div className='text-center'>
                                <Button size='sm' onClick={toggleLocalTunnel} color={localTunnel && localTunnel.open?'success':undefined}>
                                    Local tunnel
                                </Button>
                            </div>
                            <textarea
                                readOnly
                                rows={3}
                                onFocus={selectOnFocus}
                                className='code-textarea'
                                value={(localTunnel && localTunnel.open)?
                                    `ssh -p${port} ${sshUsr}@localhost`:
                                    `ssh -f -T -L ${port}:localhost:${port} iot@iot.tide.mx sleep 10;\n${(typeDevice)==='vnc'? '': sshComando} `
                                }
                                />

                        </div>
                        :
                        <><i className="tim-icons icon-alert-circle-exc"/> Encender</>
                    }
                </div>
            </CardFooter>
        </Card>
    );
};

export default TunnelToggleCard;
