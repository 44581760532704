import React, {useState, useContext, useEffect, useMemo} from 'react'
import { useCallback } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {ApiContext} from "../../../../utils/api/api-config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faHourglassHalf,
  faCheck,
  faCheckDouble,
  faBan
} from "@fortawesome/free-solid-svg-icons";
import "./ConsoleAsync.scss"
import { appsTableFromDevice } from '../../../../utils/modelUtils/releaseUtils';
import _ from "lodash";
import semver from "compare-versions";

export default function ConsoleAsync(
  {device}
) {
  const [history, setHistory] = useState([]);
  const [command, setCommand] = useState('');
  const [commandQueue, setCommandQueue] = useState('');
  const [confirmSendCommand, setConfirmSendCommand] = useState(false);
  const [confirmCleanCommands, setConfirmCleanCommands] = useState(false);
  const [deviceCommandsAvailable, setDeviceCommandsAvailable] = useState(false);

  const apps = useMemo(() => appsTableFromDevice(device), [device]);
  const minVersion = '1.3.0';
  const iotAppName = 'iot-tide';

  // Device command valid status list
  const status = {
    PENDING: 'pending',
    SENT: 'sent',
    EXECUTED: 'executed',
    ERRORED: 'errored'
  };

  const api = useContext(ApiContext);

  /**
   * handle change input commands
   */
  const handleChangeCommand = useCallback((event) => {
    setCommand(event.target.value)
  }, []);

  /**
   * Handle key press
   */
  const handleKeyPressCommand = useCallback((e) => {
    if (e.key === 'Enter') {
      if (command.trim().length > 0) {
        setCommandQueue(commandQueue + command);
      }
      setCommand('');
    }
  }, [command, commandQueue]);

  /**
   * Handle cancel commands queue
   */
  const handleCancelCommands = useCallback(() => {
    setCommandQueue('');
  }, []);

  /**
   * Handle submit commands
   */
  const handleSubmitCommands = useCallback(() => {
    if (command !== '') {
      setCommandQueue(commandQueue + command);
      setCommand('');
      setConfirmSendCommand(true);
    } else {
      if (commandQueue !== '') {
        setConfirmSendCommand(true);
      }
    }
  }, [command, commandQueue]);

  /**
   * Handle clean commands
   */
  const handleCleanCommands = useCallback(() => {
    setConfirmCleanCommands(true);
  }, []);

  const cleanCommands = useCallback(async () => {
    setConfirmCleanCommands(false);
    await api.devices.deleteCommands(device.id);
    api.deviceCommands.get({params: {'device.id': device.id, pagination: false}}).then(response => {
      let commandList = [];
      if (response) {
        response.forEach(dev => {
          commandList.push(
              {
                id: dev.id,
                command: dev.commandString,
                result: dev.result ? JSON.parse(dev.result) : {},
                status: dev.status
              }
          );
        });
      }
      setHistory(commandList);
    })
  }, [api, setHistory, device]);

  /**
   * Handle send commands
   */
  const sendCommand = useCallback(async () => {
    const commands = commandQueue.split('\n');
    let commandList = [];
    setConfirmSendCommand(false);
    for (const command of commands) {
      if (command !== '') {
        const params = {
          device: device.id,
          commandString: command
        };
        const response = await api.deviceCommands.create({params: params});
        commandList.push(
          {
            id: response.id,
            command: response.commandString,
            result: ''
          }
        );
      }
    }
    setTimeout(
      setHistory([...history, ...commandList]),
      1000
    );
    handleCancelCommands();
  }, [api.deviceCommands, commandQueue, device.id, handleCancelCommands, history]);

  /**
   * Render command text and icon
   */
  const renderCommand = useCallback(deviceCommand => {
    let icon = faHourglassHalf;
    let className = 'pending';
    if (deviceCommand.status === status.SENT) {
      icon = faCheck;
      className = 'sent';
    }
    if (deviceCommand.status === status.EXECUTED) {
      icon = faCheckDouble;
      className = 'executed';
    }
    if (deviceCommand.status === status.ERRORED) {
      icon = faBan;
      className = 'errored';
    }
    return (
      <span
        className={className}
        title={deviceCommand.status === status.ERRORED ? JSON.stringify(deviceCommand.result) : ''}
      >
        <FontAwesomeIcon icon={icon}/> {deviceCommand.command}
      </span>
    );
  }, [status.ERRORED, status.EXECUTED, status.SENT]);

  /**
   * Render errored result
   */
  const renderError = useCallback(error => {
    return (
      <>
        <br/>
        <span className='hist-result'>
          {
            error.stdout &&
            <p>
              <span>
                {error.stdout}
              </span>
            </p>
          }
          {
            error.stderr &&
            <p>
              <span>
                {error.stderr}
              </span>
            </p>
          }
        </span>
      </>
    );
  }, []);

  /**
   * Render executed result
   */
  const renderExecuted = useCallback(result => {
    return (
      <>
        <br/>
        <span className='hist-result'>
          {
            result.stdout &&
            <p>
              <span>
                {result.stdout}
              </span>
            </p>
          }
          {
            result.stderr &&
            <p>
              <span>
                {result.stderr}
              </span>
            </p>
          }
        </span>
      </>
    );
  }, []);

  /**
   * useEffect to set history commands
   */
  useEffect(() => {
    const iotApp = _.find(apps, (app) => app.name === iotAppName);
    if(iotApp && iotApp.installed && semver.compare(iotApp.installed, minVersion, '>=') ) {
      setDeviceCommandsAvailable(true);
      api.deviceCommands.get({params: {'device.id': device.id, pagination: false}}).then(response => {
        let commandList = [];
        if (response) {
          response.forEach(dev => {
            commandList.push(
              {
                id: dev.id,
                command: dev.commandString,
                result: dev.result ? JSON.parse(dev.result) : {},
                status: dev.status
              }
            );
          });
          setHistory(commandList);
        }
      })
    }
  }, [api, apps, device]);

  return (
    <div className='ConsoleAsync'>
      <div className='card'>
        <div className='card-header d-flex flex-row justify-content-between'>
            <h4>Consola</h4>
            <button
                className='btn btn-danger'
                onClick={handleCleanCommands}
            >
              Limpiar
            </button>
        </div>
        <div className='card-body'>
          {
            !deviceCommandsAvailable &&
            <p>
              La consola remota requiere la versión de iot-tide >= {minVersion}
            </p>
          }
          {
            deviceCommandsAvailable &&
            <>
              <div className='console'>
                <div className='history' >
                  {
                    history && history.map((hist) =>
                      <div
                        key={hist.id}
                        className="p-0 m-0 text-history"
                      >
                        {
                          hist.command && renderCommand(hist)
                        }
                        {
                          hist.status === status.ERRORED && renderError(hist.result)
                        }
                        {
                          hist.status === status.EXECUTED && renderExecuted(hist.result)
                        }
                      </div>
                    )
                  }
                  {commandQueue}
                </div>
                <div className='command'>
                  <textarea
                    className='command-text'
                    onChange={handleChangeCommand}
                    onKeyPress={handleKeyPressCommand}
                    value={command}
                  ></textarea>
                </div>
              </div>
              <div className='d-flex flex-row justify-content-between mt-2'>
                <button
                  className='btn btn-secondary'
                  onClick={handleSubmitCommands}
                >
                  Enviar
                </button>
                <button
                  className='btn btn-danger'
                  onClick={handleCancelCommands}
                >
                  Cancelar
                </button>
              </div>
            </>
          }
        </div>
      </div>
      {
        confirmSendCommand 
        ?
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Estás seguro de enviar estos comandos?"
            onConfirm={sendCommand}
            onCancel={() => setConfirmSendCommand(false)}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, enviar!"
            cancelBtnText="Cancelar"
            showCancel
            btnSize=""
          >
            <span className='history'>
              {commandQueue}
            </span>
          </ReactBSAlert>
        : null
      }
      {
        confirmCleanCommands
            ?
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Confirma que desea limpiar la consola?"
                onConfirm={cleanCommands}
                onCancel={() => setConfirmCleanCommands(false)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, limpiar!"
                cancelBtnText="Cancelar"
                showCancel
                btnSize=""
            >
            <span className='history'>
              {commandQueue}
            </span>
            </ReactBSAlert>
            : null
      }
    </div>
  )
}
