import {useState, useCallback} from 'react';

/**
 * Usage:
 *
 * const [ value, setTrue, setFalse, toggleValue, setValue ] = useBoolean( true );
 *
 * @param initial
 * @returns {*[]}
 */
const useBoolean = ( initial = false )=>{

    const [elBool, setElBool ]= useState( initial );

    return [
        elBool,
        useCallback( ()=>setElBool(true), [] ),
        useCallback( ()=>setElBool(false), [] ),
        useCallback( ()=>setElBool( !elBool ), [ elBool ] ),
        setElBool
    ]

};

export default useBoolean;