import React, {useMemo} from 'react';
import TunnelCard from "../../../components/TunnelCard/TunnelCard";

const SSHTunnelsRow = ({device, onChange}) => {

    const tunnels = useMemo(()=>(device && device.sshTunnels)||[], [device]);

    return (
        <div className={"row SSHTunnelsRow"}>
            { tunnels.map(tunnel=>
                <div className='col-md-4 col-sm-6 col-12' key={tunnel.id}>
                    <TunnelCard tunnel={tunnel} onChange={onChange} device={device}/>
                </div>)}
        </div>
    );
};

export default SSHTunnelsRow;
