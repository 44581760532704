import {createContext} from 'react';

const SOCKET_TIMEOUT = 3600000;

class IotClientApi {

    subscriptions=[];

    constructor() {
        if(!window.io)
            return;
        this.socket = window.io(process.env.REACT_APP_IOT_CLIENT_HOST);
        //For debug
        window.so = this.socket;
    }

    async socketCall({path, params}){
        if(!this.socket)
            throw new Error('Api socket not initialized');
        return new Promise((resolve, reject)=>{
            this.socket.emit(path, params, (...response)=>{
                resolve(...response);
            });
            setTimeout(()=>reject(new Error('socketCall timed out')), SOCKET_TIMEOUT);
        });
    }

    ready = ()=>!!(this.socket && this.socket.connected);

    subscribe(event, callback){
        if(!this.socket)
            return false;
        this.subscriptions.push({event, callback});
        this.socket.on(event, callback);
        return true;
    }

    unsubscribe(event, callback){
        if(!this.socket)
            return false;
        this.socket.off(event, callback);
        this.subscriptions = this.subscriptions.filter((s)=>!(s.event===event && s.callback===callback));
        return true;
    }


}

IotClientApi.paths = {
    //SSHTunnel routes
    sshTunnels:'/ssh-tunnel/tunnels',
    sshCreateTunnel:'/ssh-tunnel/create-tunnel',
    sshOpenTunnel:'/ssh-tunnel/open-tunnel',
    sshCloseTunnel:'/ssh-tunnel/close-tunnel',
    sshDeleteTunnel:'/ssh-tunnel/delete-tunnel',
    sshCloseAllTunnels:'/ssh-tunnel/close-all-tunnel',

};

IotClientApi.events = {
    //General
    CONNECT:'connect',
    DISCONNECT:'disconnect',
    //SSHTunnel Events
    TUNNEL_CREATED:"SSH_TUNNEL_EVENT:CREATED",
    TUNNEL_CHANGED:"SSH_TUNNEL_EVENT:CHANGED",
    TUNNEL_LIST_CHANGED:"SSH_TUNNEL_EVENT:TUNNEL_LIST_CHANGED",
    TUNNEL_DELETED:"SSH_TUNNEL_EVENT:DELETED",
    TUNNEL_ERROR:"SSH_TUNNEL_EVENT:ERROR",
};

IotClientApi.constants = {
    SSH_TUNNEL_DIR_LOCAL_TO_REMOTE: 'local_to_remote',
    SSH_TUNNEL_DIR_REMOTE_TO_LOCAL: 'remote_to_local',
};

export const IotClientContext = createContext(null);

export default IotClientApi;
