import React from 'react';
import {Card, CardBody, CardFooter, CardTitle, Col, Row} from "reactstrap";
import {trimDecimals} from "../../../utils/numbers";
import byteString from "../../../utils/byteString";

const StorageCard = ({device}) => {

    const stat = (device && device.lastStat && device.lastStat.data) || {};

    return (
        <Card className="card-stats card-action StorageCard">
            <CardBody>
                <Row>
                    <Col xs="5">
                        <div className={"info-icon text-center icon-info"}>
                            <i className="tim-icons icon-components"/>
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="numbers">
                            <p className="card-category">Almacenamiento</p>
                            <CardTitle tag="h3">
                                {stat.disk?
                                    trimDecimals((stat.disk.total-stat.disk.free)/stat.disk.total*100)+' %':
                                    'No disponible'
                                }
                            </CardTitle>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats">
                    <i className="tim-icons icon-alert-circle-exc"/>&nbsp;
                    {stat.disk?
                        (byteString(stat.disk.total-stat.disk.free))+' / '+byteString(stat.disk.total)
                        :
                        'No disponible'
                    }
                </div>
            </CardFooter>
        </Card>
    );
};

export default StorageCard;
