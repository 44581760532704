
import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import * as PropTypes from "prop-types";
import pkg from '../../../package.json'
const {version} = pkg

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid}>

          <ul className="nav">
            <li className="nav-item">
              <span className="nav-link" >
                V {version}
              </span>
            </li>
          </ul>

          <div className="copyright">
            © {new Date().getFullYear()} made with{" "}
            <i className="tim-icons icon-heart-2" /> by{" "}
            <a rel="noopener noreferrer" href="https://tide.mx" target="_blank">
              TIDE
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
