import React, {useContext, useEffect} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Row, Table} from "reactstrap";
import {NavLink} from "react-router-dom";
import {ApiContext} from "../../utils/api/api-config";
import {useSelector} from "react-redux";
import _ from 'lodash';
import useBoolean from "../../utils/hooks/useBoolean";
import NameFormModal from "../../components/NameFormModal";
import {companyListSGroups} from "../../utils/modelUtils/companyUtils";


const CompanyList = ()=>{

    const api=useContext(ApiContext);
    const [adding, startAdding, stopAdding] = useBoolean();

    useEffect(()=>{
        api.companies.get({params:{sGroups:companyListSGroups}, customProp:'CompanyList.companies'});
    },[api]);


    
    const companies = useSelector(({api})=>api['CompanyList.companies']||[]);
    
    const companiesRows = _.chunk(companies, 4);

    return (
        <div className='content CompanyList'>
            <Row>
                <Col xs={12}>
                    <Button className='pull-right' onClick={startAdding}>Agregar compañía</Button>
                </Col>
            </Row>
            {companiesRows.map( (row, i)=>
                <Row key={i}>
                    {row.map(company=>
                        <Col md={3} xs={12} key={company.id}>
                            <NavLink to={'/app/company/'+company.id}>
                                <Card className="card-stats card-action">
                                    <CardBody>
                                        <Row>
                                            <Col xs="5">
                                                <div className={"info-icon text-center icon-primary"}>
                                                    <i className="tim-icons icon-bank"/>
                                                </div>
                                            </Col>
                                            <Col xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Empresa</p>
                                                    <CardTitle tag="h3">{company.name}</CardTitle>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th>Proyectos</th>
                                                        <th className='text-center'>ONLINE</th>
                                                        <th className='text-right'>OFFLINE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <td>{company.projects.length}</td>
                                                        <td className='text-center'> {company.projects.reduce((acc, pro)=>acc+ ( pro.devicesCount ? pro.devicesCount.online : 0 ), 0)}</td>
                                                        <td className='text-center'> {company.projects.reduce((acc, pro)=>acc+ ( pro.devicesCount ? pro.devicesCount.offline : 0 ), 0)}</td>
    
                                                            
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>

                                </Card>
                            </NavLink>
                        </Col>
                    )}
                </Row>
            )}

            {adding?
                <NameFormModal
                    onHide={stopAdding}
                    entity={'companies'}
                />
                :null
            }

        </div>
    );
};

export default CompanyList;
