import React, { useState, useCallback, useContext, useMemo } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import _ from "lodash";
import {ApiContext} from "../utils/api/api-config";
import {connect} from 'react-redux';
import pluralize from 'pluralize';
//import useTraceUpdate from "../../../hooks/useTraceUpdate";

const EntitySelect = ({
                          value,
                          onChange,
                          entity,
                          creatable=false,
                          maxResults=10,
                          filterBy='name',
                          entityFromString=s=>({name:s}),
                          labelCreator= e=>e?e.name:'',
                          createPrefix='Crear ',
                          placeholder='Escribe para buscar...',
                          valueRenderer,
                          optionRenderer,
                          disabled=false,
                          createParameter,
                          additionalFilters={},
                          getMethod = 'get',
                          multi=false,
                          className='',
                          //redux
                          apiState,
                          loadingIds
                      })=>{

    const api = useContext( ApiContext );
    const [inputValue, setInputValue] = useState('');

    const loadingId = '@EntitySelect.'+entity+'.get';
    const customProp = 'EntitySelect'+entity;

    const loadEntities = useCallback(input => {
        _.debounce((input) => {
            const trimmedInput = input.trim();
            setInputValue(trimmedInput);
            if (!trimmedInput) return;
            api[entity][getMethod]({
                loadingId,
                params: {[filterBy]: trimmedInput, pageSize: maxResults, ...additionalFilters},
                customProp
            });
        }, 650)(input);
    }, [api, entity, loadingId, filterBy, maxResults, additionalFilters, customProp, getMethod]);

    const options = useMemo(() => apiState[customProp] || [], [apiState, customProp]);

    //Creates object to send as value to the Creatable component
    const createValueForSelect = useCallback((entity)=>( { value: entity, label: labelCreator(entity) } ),[labelCreator]);
    //Converts the entity received as value prop to the Creatable component notation
    const selected = useMemo( ()=>{
        if( !multi )
            return value? createValueForSelect(value):null;
        return value && value.map? value.map(createValueForSelect) : [];
    }, [value, createValueForSelect, multi] );

    const onSelectChange = useCallback( ( option )=>{

        if( !option || (option.constructor !== Array && !option.value) )
            return onChange(null);

        if(multi){
            const selected = option.map( o=>o.value );
            onChange(selected);
        }
        else if(option.value.id === 'new'){
            api[entity].create({
                [createParameter || pluralize.singular(entity)]:entityFromString(option.value.name),
                loadingId
            })
                .then(onChange);
        }
        else
            return onChange(option.value);

    }, [api, onChange, entity, entityFromString, createParameter, loadingId, multi] );

    let optionsForSelect = useMemo( ()=>options.map(createValueForSelect), [options, createValueForSelect]);

    optionsForSelect = useMemo( ()=>{
        if(options.length===0 && creatable && inputValue)
            return [{value:{id:'new', name:inputValue}, label: createPrefix+inputValue }];
        return optionsForSelect;
    }, [options, creatable, inputValue, createPrefix, optionsForSelect]);

    const SelectComponent = creatable? Creatable:Select;

    return <SelectComponent
        name="entitySelect"
        options={optionsForSelect}
        onInputChange={ loadEntities }
        onChange={ onSelectChange }
        value={ selected }
        isLoading={ !!loadingIds[loadingId] }
        placeholder={placeholder}
        valueRenderer={valueRenderer}
        optionRenderer={optionRenderer}
        disabled={disabled}
        filterOptions={o=>o}
        multi={multi}
        className={'EntitySelect '+className}
    />;
};



const mapStateToProps = ({api, loadingIds})=>({apiState:api, loadingIds});

export default connect(mapStateToProps)(EntitySelect);

