import React,{useContext,useCallback} from 'react';
import {ApiContext} from "../../../utils/api/api-config";
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";
import {NavLink} from "react-router-dom";
import moment from "moment";
import useBoolean from "../../../utils/hooks/useBoolean";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {deviceFullSGroups} from "../../../utils/modelUtils/deviceUtils";

const customProp = 'DeviceInfo.device';

const DeviceInfoCard = ({device,publicInfo}) => {

    const stat = (device && device.lastStat && device.lastStat.data) || {};

    const api = useContext(ApiContext);

    const [editingSshUSer, startEditingSshUSer, stopEditingSshUSer] = useBoolean();


    const editSshUser = useCallback((sshUser) => {
        stopEditingSshUSer();
        api.devices.update({id: device.id, params: {sshUser, sGroups: deviceFullSGroups}, customProp});
    }, [api, device, stopEditingSshUSer]);

    return (
        <Card className={"DeviceInfoCard"}>
            <CardHeader>
                <CardTitle tag="h4">Información</CardTitle>
            </CardHeader>
            <CardBody>
                <Table>
                    <tbody>
                    {!publicInfo && <tr>
                        <th>Usuario</th>
                        <td>
                        <span className='link' onClick={startEditingSshUSer}>{device.sshUser? device.sshUser:'undefined '}</span>
                        </td>
                    </tr>}
                    {!publicInfo && <tr>
                        <th>Empresa</th>
                        <td>
                            {device.company?
                                <NavLink to={'/app/company/'+device.company.id}>{device.company.name}</NavLink>:
                                'Sin compañía'}
                        </td>
                    </tr>}
                     {!publicInfo && <tr>
                        <th>Proyecto</th>
                        <td>
                            {device.project ?
                                device.project.name:
                                'Sin proyecto'}
                        </td>
                    </tr>}
                    <tr>
                        <th>Última conexión</th>
                        <td>
                            {device.connectionDate?
                                moment(device.connectionDate).format('DD/MM/YYYY HH:mm:ss'):
                                'Ninguna conexión'}
                        </td>
                    </tr>
                    <tr>
                        <th>Serie</th>
                        <td>{device.serial}</td>
                    </tr>
                    <tr>
                        <th>Throttled</th>
                        <td>{stat.throttledStatus? stat.throttledStatus:'Sin información'}</td>
                    </tr>
                    <tr>
                        <th>Uptime</th>
                        <td>{stat.uptime? stat.uptime:'Sin información'}</td>
                    </tr>
                    <tr>
                        <th>IP Public</th>
                        <td>
                            {device.publicIp? device.publicIp : 'Sin información'}
                        </td>
                    </tr>

                        {editingSshUSer ?
                <ReactBSAlert
                    input
                    style={{display: "block", marginTop: "-100px"}}
                    title="Cambiar usuario"
                    defaultValue={device.sshUser}
                    onConfirm={editSshUser}
                    onCancel={stopEditingSshUSer}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Guardar"
                    cancelBtnText="Cancelar"
                    showCancel
                >
                    Escribe el nuevo usuario
                </ReactBSAlert>
                : null

                        }


                    </tbody>
                </Table>
            </CardBody>
        </Card>




    );
};

export default DeviceInfoCard;
