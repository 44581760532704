import React, {useMemo, useCallback, useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faPlus, faStream, faTabletAlt} from "@fortawesome/free-solid-svg-icons";
import {faAffiliatetheme, faChrome, faMixcloud} from "@fortawesome/free-brands-svg-icons";
import {Button, Row} from "reactstrap";
import Switch from "react-bootstrap-switch";
import _ from "lodash";
import semver from "compare-versions";
import {appsTableFromDevice} from "../../../utils/modelUtils/releaseUtils";
import useCallbackCreator from "../../../utils/hooks/useCallbackCreator";
import {deviceFullSGroups} from "../../../utils/modelUtils/deviceUtils";
import {ApiContext} from "../../../utils/api/api-config";
import useBoolean from "../../../utils/hooks/useBoolean";
import NewTunnelModal from "../../../components/NewTunnelModal/NewTunnelModal";

const CommandsRow = ({device, customProp='devices'}) => {

    const api = useContext(ApiContext);
    const apps=useMemo(()=>appsTableFromDevice(device),[device]);
    const [addingTunnel, startAddingTunnel, stopAddingTunnel] = useBoolean();

    let commandsAvailable=true;
    const iotApp = _.find(apps, (app)=>app.name==='iot-tide');
    if(!iotApp || !iotApp.installed || semver.compare(iotApp.installed, '1.0.4', '<') )
        commandsAvailable = false;

    const stat = (device && device.lastStat && device.lastStat.data) || {};

    // ----------- commands ----------
    //This is the object that is sent from the device to the server in every connect request. It is an object with
    //the commands' names as key and the state count as value. If a command is in this object it means that is has been
    //executed by the device
    const deviceCommands = stat.commands || {};

    //This is the commands object that is sent from the server to the device in response to every connect request. If a
    //command is here it means that it should be executed by the device, it doesn't mean tho that it has been executed.
    let serverCommands={};
    if(device && device.commands)
        serverCommands=device.commands.reduce((acc, command)=>{
            acc[command.command] = command.count;
            return acc;
        },{});

    const reload = useCallback(()=>{
        api.devices.get({params:{id:device.id, sGroups: deviceFullSGroups}, customProp})
    }, [api, device, customProp]);

    const sendCommand=useCallbackCreator((command)=>{
        api.devices.executeCommand(device.id, command, {sGroups:deviceFullSGroups}, {customProp})
            .then(reload);
    },[api, device, customProp, reload]);


    // ---------------- log stats ---------------
    const toggleStatsLog=useCallback(()=>{
        api.devices.update({id:device.id, params:{statLogsEnabled:!device.statLogsEnabled, sGroups: deviceFullSGroups}, customProp});
    },[api, device, customProp]);

    const onNewTunnel = useCallback(()=>{
        reload();
        stopAddingTunnel();
    },[reload, stopAddingTunnel]);


    return (
        <Row className='CommandsRow'>

            {!commandsAvailable&&
            <div className='col-lg-3 col-md-4 col-xs-12 command-wrapper'>
                <div className='command-block'>
                    <p>Los comandos requieren iot-tide >= 1.0.4</p>
                </div>
            </div>}

            {/*REBOOT*/}
            <div className='col-lg-3 col-md-4 col-xs-12 command-wrapper'>
                <div className='command-block'>
                    <div  className='pull-left'>
                        <h4><i className="tim-icons icon-refresh-01"/> Reboot</h4>
                        <p className='small'>
                            <FontAwesomeIcon icon={faTabletAlt} /> {deviceCommands.reboot || 0} &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faMixcloud}/> {serverCommands.reboot || 0}</p>
                    </div>
                    <Button
                        className='pull-right'
                        onClick={sendCommand('reboot')}
                        color={deviceCommands.reboot===serverCommands.reboot?'default':'warning'}
                        disabled={!commandsAvailable}
                    >
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </Button>
                    <div className='clearfix'/>
                </div>
            </div>
            {/*REFRESH*/}
            <div className='col-lg-3 col-md-4 col-xs-12 command-wrapper'>
                <div className='command-block'>
                    <div  className='pull-left'>
                        <h4><FontAwesomeIcon icon={faChrome}/> Refresh</h4>
                        <p className='small'>
                            <FontAwesomeIcon icon={faTabletAlt} /> {deviceCommands.refreshBrowser || 0} &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faMixcloud}/> {serverCommands.refreshBrowser || 0}</p>
                    </div>
                    <Button
                        className='pull-right'
                        onClick={sendCommand('refreshBrowser')}
                        color={deviceCommands.refreshBrowser===serverCommands.refreshBrowser?'default':'warning'}
                        disabled={!commandsAvailable}
                    >
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </Button>
                    <div className='clearfix'/>
                </div>
            </div>
            {/*ENABLE LOGS*/}
            <div className='col-lg-3 col-md-4 col-xs-12 command-wrapper log-stats-block'>
                <div className='command-block'>
                    <div  className='pull-left'>
                        <h4><FontAwesomeIcon icon={faStream}/> Log Stats</h4>
                        <p className='small'>ENABLED</p>
                    </div>
                    <Switch onChange={toggleStatsLog} wrapperClass='pull-right' value={!!device.statLogsEnabled}/>
                    <div className='clearfix'/>
                </div>
            </div>

             <div className='col-lg-3 col-md-4 col-xs-12 command-wrapper new-tunnel-block'>
                <div className='command-block'>
                    <div  className='pull-left'>
                        <h4><FontAwesomeIcon icon={faAffiliatetheme}/> Nuevo túnel</h4>
                        <p className='small'>0</p>
                    </div>
                    <Button className='pull-right' onClick={startAddingTunnel} color='success'>
                        <FontAwesomeIcon icon={faPlus}/>
                    </Button>
                    <div className='clearfix'/>
                </div>
            </div>

            {addingTunnel &&
            <NewTunnelModal
            onClose={stopAddingTunnel}
            onCreate={onNewTunnel}
            fixedDevice={device}
            />}

        </Row>
    );
};

export default CommandsRow;
