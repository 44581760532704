import React, {useContext, useEffect, useCallback} from 'react';
import {Button, Col, Row} from "reactstrap";
import './assets/Dashboard.scss';
import DevicePill from "../../components/Devices/DevicePill";
import {ApiContext} from "../../utils/api/api-config";
import moment from "moment";
import {useSelector} from "react-redux";
import {dashSGroups, groupDevicesByCompany} from "../../utils/modelUtils/deviceUtils";
import useBoolean from "../../utils/hooks/useBoolean";
import {notify} from "../../utils/Notifier";
import {NavLink} from "react-router-dom";

const Dashboard = ()=>{
    const api=useContext(ApiContext);

    const load=useCallback(()=>{
        const fromDate = moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm');
        api.devices.get({params:{pagination: false, 'connectionDate[after]':fromDate, 'sGroups':dashSGroups}, customProp:'dashDevices'});
    },[api]);

    useEffect(load,[load]);

    const [autoRefreshing,,,toggleAutoRefreshing]=useBoolean();

    useEffect(()=>{
        if(autoRefreshing) {
            const intervalId = setInterval(load, 5000);
            notify.info('Refrescando información cada 5 segundos.');
            return () => clearInterval(intervalId);
        }
    },[autoRefreshing, load]);

    const devices=useSelector(({api})=>api.dashDevices)||[];
    const companies= groupDevicesByCompany(devices);

    return (
        <div className='content Dashboard'>
            <Row>
                <Col xs={12}>
                    <Button className='pull-right' color={autoRefreshing?'success':'default'} onClick={toggleAutoRefreshing}>Auto-refresh</Button>
                </Col>
            </Row>
            {companies.map((company)=>
                <Row key={company.id}>
                    <Col xs={12}>
                        <h2 className='online-title'><NavLink to={'/app/company/'+company.id}>{company.name}</NavLink></h2>
                    </Col>
                    {company.devices.map(device=>
                        <Col xs={12} sm={6} md={3} lg={2} key={device.id}>
                            <DevicePill device={device}/>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    );
};

export default Dashboard;
