import {useEffect, useState} from 'react';

const usePageTitle = (newTitle) => {
    const  [defaultTitle] = useState(document.title);
    useEffect(() => {
        document.title = newTitle;

        return () => {
            document.title = defaultTitle;
        }
    }, [newTitle, defaultTitle]);
}

export default usePageTitle;