//Code taken from https://gist.github.com/iamandrewluca/3f760b9218e926e1c4230a1ae3b025be
//which was originated by this issue https://github.com/reactstrap/reactstrap/issues/334
import React from 'react';
import { Card, CardBody, CardHeader, Collapse, Button } from 'reactstrap';
import PropTypes from 'prop-types';


export class Accordion extends React.Component {
    state = {
        open: this.props.open,
    };

    toggleSection = (index) => () => {
        this.setState(({open}) => ({
            open: index === open ? undefined : index
        }))
    };

    render() {
        return (
            <div className={"accordion "+(this.props.className||'')}>
                {React.Children.map(this.props.children, (child, index) => {
                    if (child.type !== AccordionItem) return null;
                    return React.cloneElement(child, {
                        isOpen: child.props.open || this.state.open === index,
                        onClick: this.toggleSection(index),
                    })
                })}
            </div>
        )
    }
}

Accordion.propTypes = {
    open: PropTypes.number,
};

const AccordionItem = ({ children, isOpen, onClick , className}) => (
    <Card className={className}>
        {React.Children.map(children, child => {
            if (child.type === AccordionHeader) {
                return React.cloneElement(child, { onClick })
            }

            if (child.type === AccordionBody) {
                return React.cloneElement(child, { isOpen })
            }

            return null
        })}
    </Card>
);

const AccordionHeader = ({ children, onClick, className }) => (
    <CardHeader className={className}>
        <h5 className="mb-0">
            <Button color="link" onClick={onClick}>
                {children}
            </Button>
        </h5>
    </CardHeader>
);

const AccordionBody = ({ children, isOpen, className }) => (
    <Collapse isOpen={isOpen}>
        <CardBody  className={className}>
            {children}
        </CardBody>
    </Collapse>
);

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
