import React, {useState, useContext, useEffect} from 'react';
import './CommentsSection.scss';
import {ApiContext} from "../../utils/api/api-config";
import {useSelector} from "react-redux";
import Comment from "../Comment/Comment";
import moment from 'moment';
//import {deviceFullSGroups} from "../../utils/modelUtils/deviceUtils";

const CommentsSection = ({cassette, deviceID, theDevice, refresh}) => {

    const api = useContext(ApiContext);

    const [error, setError] = useState(false);
    const [comment, setComment] = useState({
        text: '',
        isRemoved: false
    });

    useEffect(() => {
        if (theDevice.cassette !== null) {
            api.comment.getComments(theDevice.cassette.id);
        }
    }, [api, cassette, theDevice]);

    const commentHandler = (e) => {
        setComment({...comment, text: e.target.value});
    };

    const submitCommentHandler = () => {
        if (comment.text === '') {
            setError(true);
            return;
        }

        if (cassette === null) {
            api.devices.update({id: deviceID, params: {cassette: {comments: [comment]}}}).then(() => {
                refresh();
                reloadHandler();
            });
            document.getElementById('text-area').value = '';
            setComment({
                ...comment,
                text: ''
            });
        } else {
            api.comments.create({params: {...comment, cassette: theDevice.cassette.id}}).then(() => reloadHandler());

            document.getElementById('text-area').value = '';
            setComment({
                ...comment,
                text: ''
            });
        }
    };

    const deleteCommentHandler = (e) => {
        api.comment.putComments(e.target.parentNode.id, {isRemoved: true}).then(() => reloadHandler());
    };

    const closeErrorHandler = () => {
        setError(!error);
    };

    const reloadHandler = () => {
        if (theDevice.cassette !== null) {
            api.comment.getComments(theDevice.cassette.id);
        }
    }

    const theComments = useSelector(({api}) => api.commentsGet || []);

    return (
        <div className='card'>
            <div className='card-header'>
                <h4>Comentarios</h4>
            </div>
            <div className='card-body'>
                <div className='comments-history'>
                    {theComments.map(comment => {
                            const dateTransform = moment(comment.createdDate).format('DD-MMMM-YYYY, h:mm:ss a');
                            return comment.isRemoved === false ?
                                <Comment
                                    key={comment.id}
                                    theComment={comment}
                                    reload={refresh}
                                    createdDate={dateTransform}
                                    deleteComment={deleteCommentHandler}
                                />
                                : null
                        }
                    )}
                </div>
                <div className='comments-submit'>
                    <textarea className='comment-text' id='text-area' name='commentText' defaultValue=''
                              value={comment.commentText}
                              placeholder='Escribe tu nuevo comentario' onChange={commentHandler}/>
                    <button className='btn btn-secondary' onClick={submitCommentHandler}>Enviar</button>
                </div>
            </div>

            {error ? <div className='error-comment'>
                <p className='error-text'>Error al mandar comentario.</p>
                <button className='btn btn-secondary' onClick={closeErrorHandler}>Aceptar</button>
            </div> : null}
        </div>
    )
};

export default CommentsSection;