import React, {useContext, useCallback} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import DevicePill from "../../components/Devices/DevicePill";
import {ApiContext} from "../../utils/api/api-config";
import ReactBSAlert from "react-bootstrap-sweetalert";
import useBoolean from "../../utils/hooks/useBoolean";

const ReleaseDetailModal=({release, onHide})=>{

    const api=useContext(ApiContext);
    const [removingRelease, startRemovingRelease, stopRemovingRelease]=useBoolean();

    const deleteRelease=useCallback(()=>{
        stopRemovingRelease();
        api.releases.delete({id:release.id})
            .then(onHide);
    },[stopRemovingRelease, api, release, onHide]);

    return (
        <>
            <Modal isOpen toggle={onHide} size='lg'>
                <ModalHeader toggle={onHide} tag={'h3'}>{release.application.name} - {release.version}</ModalHeader>
                <ModalBody>
                    <div className='container-fluid'>
                        <Row>
                            {release.deviceReleases.length?
                                release.deviceReleases.map(({device})=><Col xs={12} sm={6} md={3} key={device.id} ><DevicePill device={device}/></Col>)
                        :
                        <Col xs={12}><p>Ningún dispositivo tiene esta aplicación.</p></Col>
                    }
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <hr/>
                                <Button color='danger' onClick={startRemovingRelease}><i className='tim-icons icon-trash-simple'/></Button>
                                <a
                                    href={`${api.releases.downloadUrl(release.id)}?token=${api.token}`}
                                    rel='noopener noreferrer'
                                    target="_blank"
                                >
                                    <Button color='success' className='pull-right'><i className='tim-icons icon-cloud-download-93'/></Button>
                                </a>
                                <hr/>
                            </Col>
                        </Row>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onHide}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            {removingRelease?
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={deleteRelease}
                    onCancel={stopRemovingRelease}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Shhh, solo bórrala!"
                    cancelBtnText="Cancelar"
                    showCancel
                    btnSize=""
                >
                    ¿Tantos bugs tenía que quieres borrar todo registro de tu aplicación?
                </ReactBSAlert>
                :null}
        </>
    );
};

export default ReleaseDetailModal;
