import React, {useMemo, useState, useCallback, useContext} from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, Modal, ModalBody,
    ModalFooter, ModalHeader, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {appsTableFromDevice, releaseSelectSGroups} from "../../../utils/modelUtils/releaseUtils";
import useCallbackCreator from "../../../utils/hooks/useCallbackCreator";
import useBoolean from "../../../utils/hooks/useBoolean";
import _ from "lodash";
import {deviceFullSGroups} from "../../../utils/modelUtils/deviceUtils";
import ReactBSAlert from "react-bootstrap-sweetalert";
import EntitySelect from "../../../components/EntitySelect";
import {useSelector} from "react-redux";
import {notify} from "../../../utils/Notifier";
import {ApiContext} from "../../../utils/api/api-config";

const appsSelectFilters = {sGroups:releaseSelectSGroups};


const AppsTableCard = ({device, customProp,publicInfo}) => {

  const api = useContext(ApiContext);

  const apps = useMemo(() => appsTableFromDevice(device), [device]);
  const [deletingApp, setDeletingApp] = useState(null);
  const [addingApp, startAddingApp, stopAddingApp] = useBoolean();
  const [selectedApp, setSelectedApp] = useState(null);


  const promptRemoveApp = useCallbackCreator( name => setDeletingApp(name), []);
  const stopRemovingApp = useCallback(() => setDeletingApp(null), []);

  const removeApp = useCallback(() => {
    const releaseIndex = _.findIndex(device.deviceReleases, dr=>dr.release.application.name === deletingApp );
    if (releaseIndex===-1) {
        console.log(deletingApp + ' app not found', device.deviceReleases);
        return setDeletingApp(null);
    }
    const newReleases = _.map(device.deviceReleases, 'id');
    newReleases.splice(releaseIndex, 1);
    api.devices.update(
      {
        id: device.id,
        params: {
          id: device.id,
          deviceReleases: newReleases,
          sGroups: deviceFullSGroups
        },
        customProp
      }
    );
    return setDeletingApp(null);
  },[deletingApp, api, device, customProp]);

  const addingAppLoadingId='DeviceInfo.devices.update';
  const addingAppLoading = !!useSelector(({loadingIds}) => loadingIds[addingAppLoadingId]);

  const addApp=useCallback(() => {
    if (!selectedApp) {
      return notify.error('Debes seleccionar una aplicación.');
    }

    const deviceReleases=_.map(device.deviceReleases, 'id');
    const sameApp=_.findIndex(device.deviceReleases, (deviceRelease)=>deviceRelease.release.application.id===selectedApp.application.id);

    if (sameApp !== -1) {
      deviceReleases[sameApp] = {
        release: selectedApp.id,
        id: deviceReleases[sameApp]
      };
    } else {
      deviceReleases.push(
        {
          release: selectedApp.id,
          application: selectedApp.application.id
        }
      );
    }

    api.devices.update(
      {
        id: device.id,
        params: {
          deviceReleases,
          sGroups: deviceFullSGroups
        },
        customProp,
        loadingId: addingAppLoadingId
      }
    ).then(stopAddingApp);
  },[api, device, selectedApp, stopAddingApp, customProp]);

  return (
    <Card className='AppsTableCard'>
      <CardHeader>
        <CardTitle tag="h4">
          Aplicaciones
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <thead className="text-primary">
            <tr> 
              <th>Nombre</th>
              <th>Instalada</th>
              <th>Configurada</th>
              {
                !publicInfo && 
                <th className='trash-cell'/>
              }
            </tr>
          </thead>
          <tbody>
            {apps.map((app, i) =>
              <tr key={i}>
                <td>{app.name}</td>
                <td>{app.installed}</td>
                <td>{app.configured}</td>
                <td className='trash-cell'>
                  {
                    app.configured &&
                    !publicInfo &&
                    <FontAwesomeIcon
                      icon={faTrash}
                      className='icon'
                      onClick={promptRemoveApp(app.name)}
                    />
                  }
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {
          !publicInfo && 
          <Button onClick={startAddingApp}>
            Agregar aplicación
          </Button>
        }
      </CardBody>

      {
        deletingApp
          ? <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={`¿Borrar aplicación ${deletingApp}?`}
              onConfirm={removeApp}
              onCancel={stopRemovingApp}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Sí, borrar!"
              cancelBtnText="Cancelar"
              showCancel
              btnSize=""
            >
              Esto no la eliminará del dispositivo, solo evitará que sea instalada de nuevo por el iot.
            </ReactBSAlert>
          : null
      }


      {
        addingApp
          ? <Modal isOpen>
              <ModalHeader>
                Agregar aplicación
              </ModalHeader>
              <ModalBody>
                <EntitySelect
                  entity={'releases'}
                  filterBy={'search'}
                  additionalFilters={appsSelectFilters}
                  value={selectedApp}
                  onChange={setSelectedApp}
                  labelCreator={release=>`${release.application.name} - ${release.version}`}
                />
              </ModalBody>
              <ModalFooter>
                <Button color='danger' disabled={addingAppLoading} onClick={stopAddingApp}>Cancelar</Button>
                <Button color='success' disabled={addingAppLoading} onClick={addApp}>Agregar</Button>
              </ModalFooter>
            </Modal>
          : null
      }

    </Card>
  );
};

export default AppsTableCard;
