import React from "react";
import { Route, Switch } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import getRoutes from "../../routes.js";
import logo from "../../assets/img/tide-logo.png";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {LOGIN_STATE} from 'tide-api';
import withApi from "../../utils/api/withApi";
import {IotClientContext} from "../../utils/api/IotClientApi";


class AppLayout extends React.Component {

  static contextType = IotClientContext;

  constructor(props) {
    super(props);
    this.state = {
      activeColor: window.localStorage.color || "red",
      sidebarMini: window.localStorage.sidebarMini === 'true',
      opacity: 0,
      sidebarOpened: false
    };

    this.mainPanelRef = React.createRef();
    this.notificationAlert = React.createRef();

    props.api.me.get();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.showNavbarButton);
    if( this.state.sidebarMini )
      document.body.classList.add("sidebar-mini");

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showNavbarButton);
  }

  showNavbarButton = () => {
    if (document.documentElement.scrollTop > 50 && !this.state.opacity)
      this.setState({ opacity: 1 });
    else if (document.documentElement.scrollTop <= 50 && this.state.opacity)
      this.setState({ opacity: 0 });
  };

  getAvailableRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getActiveRoute = routes => {

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute)
          return collapseActiveRoute;
      }
      else {
        if (this.props.location.pathname.indexOf((routes[i].layout + routes[i].path).replace(/\/:[^/]*/g, "")) > -1 ? 'active' : '')
          return routes[i].name;
      }
    }
    return false;
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      window.localStorage.sidebarMini = 'false';
    } else {
      this.setState({ sidebarMini: true });
      window.localStorage.sidebarMini = 'true';
    }
    document.body.classList.toggle("sidebar-mini");
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false
    });
    document.documentElement.classList.remove("nav-open");
  };
  render() {

    if( this.props.loggedIn !== LOGIN_STATE.LOGGED_IN )
      return <Redirect to='/auth/login'/>;

    const routes = getRoutes({iotClientApi: this.context});
    const availableRoutes = this.getAvailableRoutes(routes);

    return (
      <div className="wrapper">
        <div className="rna-container">
          <NotificationAlert ref={this.notificationAlert} />
        </div>
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          activeColor={this.state.activeColor}
          logo={{
            innerLink: '/',
            text: "T I D E",
            imgSrc: logo
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={this.getActiveRoute(routes)}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />
          <Switch>{availableRoutes}</Switch>

          <Footer fluid />
        </div>

      </div>
    );
  }
}

const mapStateToProps = ( {api:{loggedIn}} )=>({loggedIn});

export default connect(mapStateToProps)(withApi(AppLayout));
